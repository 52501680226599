<template>
  <div>

    <tool-bar
      :form="form"
    />

    <div class="row">
      <div class="col-md-3 col-sm-12">
        <div class="card">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-6">
              <p class="card-title">
                Edit {{ $route.meta.title }}
              </p>
              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-end">
                <template v-if="form.link && form.domain">
                  <a :href="'https://'+form.link+'.'+form.domain" target="_blank" class="btn btn-primary btn-sm">
                    <i class="fa fa-external-link-alt"></i> Open
                  </a>                
                </template>
              </div><!--Col-->

            </div><!--Row-->

            <form @submit.prevent="postForm()">


              <div class="form-group">
                <label for="name">{{ $route.meta.title }} Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="portal_reference">Portal Reference</label>
                <input
                  id="portal_reference"
                  v-model="form.portal_reference"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="default"
                  v-model="form.default"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="default"
                >Default</label>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>
  
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
          <div class="card-body">
            <p class="card-title"><i class="fa fa-palette"></i> Theme and Brand</p>

            <div class="form-group">
              <label for="theme">Color Theme</label>
              <select id="theme" class="form-control" v-model="form.settings['theme']" @change="postForm()" :disabled="processing">
                <option value=""></option>
                <template v-for="(theme, theme_index) in themes">
                  <option :value="theme">{{ theme }}</option>
                </template>
              </select>
            </div>

            <div class="form-group">
              <label for="logo">LOGO URL</label>
              <input
                  id="logo"
                  v-model="form.settings['logo']"
                  type="text"
                  class="form-control"
                >
            </div>

            <div class="form-group">
              <label for="icon">ICON URL</label>
              <input
                  id="icon"
                  v-model="form.settings['icon']"
                  type="text"
                  class="form-control"
                >
            </div>


            <template v-if="form.settings && form.settings['icon']">

              <img
                  width="32"
                  class="mr-3"
                  :src="form.settings['icon']"
                  :alt="form.name + ' Icon'"
              >

              <div class="btn-group my-3">
                <button
                    type="button"
                    class="btn btn-outline-secondary btn-sm"
                    @click="copyToClipboard(iconURL)"
                    :disabled="processing">
                  <i class="fa fa-copy"></i> Copy
                </button>

                <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    @click="trashIcon()"
                    :disabled="processing"
                >
                  <i class="fa fa-trash" /> Trash
                </button>
              </div>
            </template>

            <div class="form-group mt-3">
              <label for="custom_css">Custom CSS</label>
              <textarea
                  id="custom_css"
                  v-model="form.settings['custom_css']"
                  class="form-control"
                  @change="postForm()"
              />
            </div>
            <div class="form-group">
              <label for="custom_js">Custom JS</label>
              <textarea
                  id="custom_js"
                  v-model="form.custom_js"
                  class="form-control"
                  @change="postForm()"
              />
            </div>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
          <div class="card-body">

            <p class="card-title"><i class="fa fa-link"></i> Links and Redirects</p>

            <form @submit.prevent="postForm()">

            <div class="form-group">
              <label for="link">Portal Link</label>
              <input
                  id="link"
                  v-model="form.link"
                  class="form-control"
                  required
              >
            </div>

            <div class="form-group">
              <label for="domain">Domain</label>
              <select
                  id="domain"
                  v-model="form.domain"
                  class="form-control"
                  :disabled="processing"
                  required
              >
                <template v-for="(domain, domain_key) in domains">
                  <option :value="domain.apex">{{ domain.apex }}</option>
                </template>
              </select>
              <template v-if="form.alias">
                <p class="form-text text-muted small">Alias for {{ form.alias }}</p>
              </template>
            </div>

            <div class="form-group">
                <label for="redirect_link">Redirect Link</label>
                <input
                    id="redirect_link"
                    v-model="form.settings['redirect']"
                    type="text"
                    class="form-control"
                >
                <p class="form-text text-muted small">Auto-redirect to an external link.</p>
            </div>

            <button type="submit" class="btn btn-success sr-only" :disabled="processing">Save</button>

            </form>

          </div>
        </div>

        <div class="card my-3">
          <div class="card-body">

            <p class="card-title"><i class="fa fa-map-location-dot"></i> Tracking and Authentication</p>

            <div class="form-group mt-3">
              <label for="geolocation">Geolocation Tracking</label>
              <select
                  id="geolocation"
                  v-model="form.geolocation"
                  class="form-control"
                  :disabled="processing"
              >
                <option value=""></option>
                <template v-for="(geolocation, geolocation_index) in geolocations">
                  <option :value="geolocation">{{ geolocation }}</option>
                </template>
              </select>
              <p class="form-text text-muted small">Can be overridden by Flow.</p>
            </div>

            <div class="form-group">
              <label for="portal_verify">Client Verification</label>
              <select
                  id="portal_verify"
                  v-model="form.verify"
                  class="form-control"
                  :disabled="processing"
              >
                <option value=""></option>
                <template v-for="(verification, verification_index) in verifications">
                  <option :value="verification">{{ verification }}</option>
                </template>
              </select>
            </div>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
              <div class="card-body">

                <p class="card-title"><i class="fa fa-share"></i> Embed and Share</p>


             <div class="form-group">
              <label for="embed_code">iFrame Embed Code</label>
              <textarea
                  id="embed_code"
                  v-model="embedCode"
                  class="form-control"
                  rows="3"
                  @click="copyToClipboard(embedCode)"
                  readonly
              />
            </div>

              </div><!--Body-->
        </div><!--Card-->

        <audit-logs
          class="mt-3"
          :form="form"
        />

      </div><!--Col-->

      <div class="col-sm-12 col-md-6">

        <div class="card mb-3">
          <div class="card-body">

            <h4>Payment Options</h4>

            <div class="form-group custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="show_payment_options" v-model="form.settings['show_payment_options']">
              <label class="custom-control-label" for="show_payment_options">Show Payment Options on Landing Page</label>
            </div>

            <div class="form-group custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="disable_payment_lookups" v-model="form.settings['disable_payment_lookups']">
              <label class="custom-control-label" for="disable_payment_lookups">Disable Payment Lookups on Landing Page</label>
            </div>

            <div class="form-group">
              <label for="payment_info">Payment Information</label>
              <textarea id="payment_info" class="form-control" v-model="form.settings['payment_info']" rows="5"></textarea>
              <p class="form-text text-muted small">General payment information displayed above payment options</p>
            </div>

            <div class="btn-group my-3" role="group" aria-label="Basic checkbox toggle button group">

              <input type="checkbox" class="btn-check" id="pay_ach" v-model="form.settings['ach']" autocomplete="off">
              <label class="btn btn-outline-primary" for="pay_ach">ACH</label>

              <input type="checkbox" class="btn-check" id="pay_phone" v-model="form.settings['phone']" autocomplete="off">
              <label class="btn btn-outline-primary" for="pay_phone">Pay by Phone</label>

              <input type="checkbox" class="btn-check" id="pay_mail" v-model="form.settings['mail']" autocomplete="off">
              <label class="btn btn-outline-primary" for="pay_mail">Postal Mail</label>

              <input type="checkbox" class="btn-check" id="pay_inperson" v-model="form.settings['inperson']" autocomplete="off">
              <label class="btn btn-outline-primary" for="pay_inperson">In-Person</label>

              <input type="checkbox" class="btn-check" id="external_portal" v-model="form.settings['external_portal']" autocomplete="off">
              <label class="btn btn-outline-primary" for="external_portal">External Portal</label>

            </div>

            <template v-if="form.settings['ach']">
              <p class="m-0 lead"><i class="fa fa-bank"></i> Provide ACH Instructions</p>
              <div class="form-group">
              <label class="form-label" for="ach_instructions">ACH Instructions</label>
              <textarea id="ach_instructions" class="form-control" v-model="form.settings['ach_instructions']" rows="5"></textarea>
              </div>

              <div class="form-group">
              <label class="form-label" for="ach_account_name">Account Name</label>
              <input id="ach_account_name" class="form-control" v-model="form.settings['ach_account_name']">
              </div>

              <div class="form-group">
              <label class="form-label" for="ach_bank_name">Bank Name</label>
              <input id="ach_bank_name" class="form-control" v-model="form.settings['ach_bank_name']">
              </div>

              <div class="form-group">
              <label class="form-label" for="ach_routing_number">Routing Number</label>
              <input id="ach_routing_number" class="form-control" v-model="form.settings['ach_routing_number']">
              </div>

              <div class="form-group">
              <label class="form-label" for="ach_account_number">Account Number</label>
              <input id="ach_account_number" class="form-control" v-model="form.settings['ach_account_number']">
              </div>

              <button type="button" class="btn btn-primary btn-sm my-3" @click="setSample('ach')">Use Sample</button>

            </template>

            <template v-if="form.settings['external_portal']">

              <p class="m-0 lead"><i class="fa fa-computer"></i> Link to an external payment portal.</p>

            <div class="form-group">
              <label for="external_portal_link">External Portal Link</label>
              <input id="external_portal_link" type="text" class="form-control" v-model="form.settings['external_portal_link']" placeholder="https://pay.court.gov">
            </div>

            <div class="form-group">
              <label class="form-label" for="external_portal_instructions">External Portal Instructions</label>
              <textarea id="external_portal_instructions" class="form-control" v-model="form.settings['external_portal_instructions']" rows="5"></textarea>
            </div>

            <button type="button" class="btn btn-primary btn-sm my-3" @click="setSample('external_portal')">Use Sample</button>

            </template>

            <template v-if="form.settings['phone']">

              <p class="m-0 lead"><i class="fa fa-phone"></i> Accept payments through an automated phone number.</p>

              <div class="form-group">
                <label for="pay_phone_number">Phone Number</label>
                <input id="pay_phone_number" type="text" class="form-control" v-model="form.settings['phone_number']">
              </div>

              <div class="form-group">
                <label class="form-label" for="phone_instructions">Phone Instructions</label>
                <textarea id="phone_instructions" class="form-control" v-model="form.settings['phone_instructions']" rows="5"></textarea>
              </div>

              <button type="button" class="btn btn-primary btn-sm my-3" @click="setSample('phone')">Use Sample</button>

            </template>


            <template v-if="form.settings['mail']">

              <p class="m-0 lead"><i class="fa fa-envelope"></i> Accept payments through postal mail.</p>

              <div class="form-group">
                <label class="form-label" for="pay_mail_instructions">Mail Instructions</label>
                <textarea id="pay_mail_instructions" class="form-control" v-model="form.settings['mail_instructions']" rows="5"></textarea>
              </div>

              <div class="form-group">
                <label class="form-label" for="pay_mail_address">Postal Address</label>
                <input id="pay_mail_address" class="form-control" v-model="form.settings['mail_address']">
              </div>

              <div class="form-group">
                <label class="form-label" for="pay_mail_pdf">PDF Template</label>
                <input id="pay_mail_pdf" class="form-control" v-model="form.settings['mail_pdf']">
              </div>

              <button type="button" class="btn btn-primary btn-sm my-3" @click="setSample('mail')">Use Sample</button>

            </template>

            <template v-if="form.settings['inperson']">

              <p class="m-0 lead"><i class="fa fa-hand"></i> Accept payments in person.</p>

              <div class="form-group">
                <label class="form-label" for="pay_inperson_instructions">In-Person Instructions</label>
                <textarea id="pay_inperson_instructions" class="form-control" v-model="form.settings['inperson_instructions']" rows="3"></textarea>
              </div>

              <div class="form-group">
                <label class="form-label" for="pay_inperson_location">In-Person Location</label>
                <select id="pay_inperson_location" class="form-control" v-model="form.settings['inperson_location']" required>
                  <option value=""></option>
                  <template v-for="(location, location_index) in locations">
                    <option :value="location.uuid">{{ location.name }}</option>
                  </template>
                </select>
              </div>

              <button type="button" class="btn btn-primary btn-sm my-3" @click="setSample('inperson')">Use Sample</button>


            </template>


            <template v-if="form.settings['mode'] && form.settings['mode'] == 'test'">
              <h6>How to Simulate Payment Processing</h6>
              <p>Use one of the following to test a card transaction. Any future expiration date will work.</p>
              <ul class="list-group">
                <li class="list-group-item">
                  <img :src="$root.assets_url+'images/payments/visa.svg'" width="40" alt="Visa logo">
                  <mark>4242424242424242</mark>
                  <span class="badge badge-pill badge-secondary" @click="copyToClipboard('123')">CVC 123</span>
                  <button type="button" class="btn btn-secondary btn-sm float-right" @click="copyToClipboard('4242424242424242')" :disabled="processing"><i class="fa fa-copy"></i> <span class="sr-only">Copy</span></button>
                </li>
                <li class="list-group-item">
                  <img :src="$root.assets_url+'images/payments/mastercard.svg'" width="40" alt="MasterCard logo">
                  <mark>5555555555554444</mark>
                  <span class="badge badge-pill badge-secondary" @click="copyToClipboard('123')">CVC 123</span>
                  <button type="button" class="btn btn-secondary btn-sm float-right" @click="copyToClipboard('5555555555554444')" :disabled="processing"><i class="fa fa-copy"></i> <span class="sr-only">Copy</span></button>
                </li>
                <li class="list-group-item">
                  <img :src="$root.assets_url+'images/payments/discover.svg'" width="40" alt="Discover logo">
                  <mark>6011111111111117</mark>
                  <span class="badge badge-pill badge-secondary" @click="copyToClipboard('123')">CVC 123</span>
                  <button type="button" class="btn btn-secondary btn-sm float-right" @click="copyToClipboard('6011111111111117')" :disabled="processing"><i class="fa fa-copy"></i> <span class="sr-only">Copy</span></button>
                </li>
                <li class="list-group-item">
                  <img :src="$root.assets_url+'images/payments/amex.svg'" width="40" alt="American Express logo">
                  <mark>378282246310005</mark>
                  <span class="badge badge-pill badge-secondary" @click="copyToClipboard('1234')">CVC 1234</span>
                  <button type="button" class="btn btn-secondary btn-sm float-right" @click="copyToClipboard('378282246310005')" :disabled="processing"><i class="fa fa-copy"></i> <span class="sr-only">Copy</span></button>
                </li>
              </ul>
            </template>


          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->

      <div class="col-sm-12 col-md-3">

      </div><!--Col-->

    </div><!--Row-->
  </div>
</template>
<script>

export default {

  data () {
    return {
      processing: false,
      total: 0,
      queries: {
        limit: 100,
        fields: 'name,uuid,link,portal_reference,redirect,created_at',
        sort: 'name',
        sortDirection: 'asc'
      },
      records: [],
      form: {
        name: null,
        link: null,
        settings: {

        }
      },
      asset_url: 'https://ecdportals.s3-us-gov-east-1.amazonaws.com',
      columns: ['name', 'link', 'reference', 'redirect', 'created_at'],
      enroll_fields: [],
      qr_code: null,
      domains: [
        {'apex': 'govpayments.io'}
      ],
      geolocations: ['disabled', 'optional', 'required', 'continuous'],
      verifications: ['disabled', 'one-time-password', 'contact', 'biometric'],
      portal_status: 'loading',
      themes: ['auto', 'light', 'dark'],
      locations: [],
      samples: {
        'ach': {
          'ach_instructions': 'Please provide the following information to your bank to setup ACH payments.',
          'ach_account_name': 'Agency Name',
          'ach_bank_name': 'Bank Name',
          'ach_routing_number': '123456789',
          'ach_account_number': '987654321'
        },
        'phone': {
          'phone_instructions': 'Call 1-800-555-1212 to make a payment over the phone.',
          'phone_number': '1-800-555-1212'
        },
        'mail': {
          'mail_instructions': 'Mail a check or money order to the following address.',
          'mail_address': '123 Main St, Your Town, State 12345'
        },
        'inperson': {
          'inperson_instructions': 'Visit the following location to make a payment in person. Cash, credit, and debit cards accepted. No checks. Open 9am-5pm, Monday-Friday.'
        },
        'external_portal': {
          'external_portal_instructions': 'Visit the following link to make a payment.',
          'external_portal_link': 'https://pay.court.gov'
        }
      }
    }
  },

  computed: {
        embedCode() {
          if(!this.form || !this.form.link) {
            return;
          }

          return '<iframe src="https://'+this.form.link+'.'+this.form.domain+'" title="'+this.form.name+'" width="100%" height="100%" frameborder="0" border="0" loading="eager" allowfullscreen="true" allowpaymentrequest="true"></iframe>';
        },

  },

  created() {    
    this.resetForm();
    this.setURLParams();
    this.getRecord();
    this.getLocations();
  },

  methods: {

    setTitle() {
      var name = this.form.name ? this.form.name : 'New '+this.$route.meta.title;
        document.title = name + ' | '+this.$root.title;
    },

    setSample(type) {
      if(this.samples[type]) {
        this.form.settings = {...this.form.settings, ...this.samples[type]};
      }
    },

    postForm() {
      this.processing = true;
      
      this.$http.post('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.data.updated_at) {
            this.form = {...this.form, ...response.data};
            this.$eventBus.$emit('alerts', {type: 'success', message: 'Saved '+this.$route.meta.title+'.'});
          }

        }
      })
    },

    resetForm() {

        this.qr_code = null;

        this.form = {
            name: '',
            link: '',
            domain: '',
            settings: {}
        }
    },


   getRecord() {
    this.processing = true;
    this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
    .then(response => {
      if(response && response.data) {

        if(response.data.uuid) {
          this.form = response.data;
          this.setTitle();

          if(!this.form.settings) {
            this.form.settings = {};
          }

        }

        this.processing = false;
      }
    })
   },

   getLocations() {
    this.$http.get('/locations?sort=name&sortDirection=asc')
    .then(response => {
      if(response && response.data) {
        this.locations = response.data;
      }
    })
   },

    getPortalStatus() {

      if(!this.form.link || !this.form.domain) {
        return;
      }

      this.portal_status = 'loading';

      this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/status')
          .then(response => {
            if(response && response.status) {
              this.portal_status = response.data;
            }
          })
    },

   setFields() {

    var fields = ['title', 'alerts', 'sidebar', 'welcome', 'home', 'footer', 'body', 'calendar_title', 'calendar_description'];

    fields.forEach(f => {
        if(!this.form[f]) {
            this.form[f] = {'en': ''};
        }
    });

    if(!this.form['optouts'] || !this.form['optouts']['reasons']) {
      this.form['optouts'] = {
        reasons: {
          'en': ''
        }
      }
    }

    if(!this.form['optouts']['title']) {
      this.form['optouts']['title'] = {
        'en': ''
      }
    }
    
   },

      getQRCode() {

        if(!this.form.link) {
          return;
        }

        this.$http.post('/qrs?content='+this.setPortalLink())
        .then(response => {
            if(response && response.data) {
                this.qr_code = response.data;
            }
        })
      },

      restoreRecord () {
          this.processing = true;
          this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
          .then(response => {
              if (response && response.data) {

                  this.processing = false;

                  if(response.status === 200) {
                      this.form.deleted_at = null;
                      this.form.archived_at = null;
                  }
                  
              }
          })
      },

      archiveRecord () {
          this.processing = true
          this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
          .then(response => {
              if (response && response.data) {

                  this.processing = false;

                  if(response.status === 200) {
                      this.form.archived_at = new Date().toISOString();
                  }

              }
              
          })
      },

      trashRecord () {
          this.processing = true;
          this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
          .then((response) => {
            if(response && response.status) {
              this.processing = false;

              if(response.status === 204) {
                this.form.deleted_at = new Date().toISOString();
              }
            }
          })
      },


    setPortalLink() {

      if(!this.form.link) {
        return;
      }

      var domain = this.form.domain || 'govpayments.io';

      return 'https://'+this.form.link+'.'+domain+'/';
    },

    copyToClipboard(value) {
        navigator.clipboard.writeText(value);
    },

  },
}
</script>