<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary mb-3">
  <div class="container-fluid">
    <a class="navbar-brand" href="/dashboard">
      <img :src="$root.logo" :alt="$root.title + ' logo'" width="80" class="d-inline-block align-text-top">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <template v-for="(nav, index) in navs">
          <li class="nav-item">
            <router-link :to="{ name: nav+'.index' }" class="nav-link text-capitalize" :class="{ active: $route.name == nav }">
              {{ nav | rmDashes }}
            </router-link>
          </li>
        </template>
      </ul>

      <ul class="navbar-nav mb-2 mb-lg-0">
        <template v-if="$root.user && $root.user.uuid">
        <li class="nav-item">
          <a class="nav-link" :href="'/users/'+$root.user.uuid">
            User
          </a>
        </li>
        </template>
        <li class="nav-item">
          <a class="nav-link" href="/logout">
            Logout
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
  </div>
</template>
<script>
export default {

    data () {
        return {
            processing: false,
            navs: ['dashboard', 'payments', 'transactions', 'pay_links', 'pay_plans', 'recurring_payments', 'gift_cards', 'reports', 'settings']
        }
    },

    computed: {



    },

    created() {

    },

    mounted() {


    },

    methods: {


    },
    
}
</script>
