import Vue from 'vue';
import axios from 'axios';

const apiURL = process.env.VUE_APP_API_URL;

Vue.prototype.$apiURL = apiURL;

const token = localStorage.getItem('access_token');

const http = axios.create({
  baseURL: apiURL+'v1',
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }
});

http.defaults.headers.common.Authorization = 'Bearer ' + token;

Vue.prototype.$eventBus = new Vue();
Vue.prototype.$http = http;

Vue.prototype.$http.interceptors.response.use(
  function (response) {
    if (!localStorage.getItem('access_token')) {
      window.location.href = '/logout';
    }

    return response;
  },
  function (error) {

    Vue.prototype.$eventBus.$emit('errors', error.response || error);

    if (error.response && error.response.data && error.response.data.message) {
        if(error.response.status != 403 && error.response.status != 404) {
          Vue.prototype.$eventBus.$emit('error', error.response.data.message);
        }
    }

    if(!localStorage.getItem('access_token')) {
      window.location.href = '/logout';
    }

  }
)

export default http
