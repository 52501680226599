<template>
<div>
    <template v-for="alert in alerts">
        <div class="toast-container position-fixed bottom-0 end-0 p-3">
        <div class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
            <strong class="me-auto text-uppercase"><i class="fa fa-robot"></i> {{ alert.from }}</strong>
            <small class="text-body-secondary">{{ alert.time | timeago }}</small>
            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
            {{ alert.message }}
        </div><!--Body-->
        </div><!--Toast-->
        </div><!--Container-->
    </template>
</div>
</template>
<script>
export default {

data() {
    return {
        alerts: [],
        showToast: true
    }
},

    mounted() {
        var self = this;

        this.$eventBus.$on('alerts', function (data) {

            data.time = this.$moment();

            if(!data.from) {
                data.from = 'GPP Bot';
            }

            self.alerts.unshift(data);

            setTimeout(function() {
                self.alerts.pop();
            }, 5000);

        });
    },

    created() {

    },

methods: {

    toggleToast() {
      this.showToast = !this.showToast;
    }

}

}
</script>