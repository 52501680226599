<template>
  <div>

    <page-tabs :page="$route.meta.base_url" />

    <div class="container">

    <div class="row">
      <div class="col-md-6">
        <template v-if="form.name">
          <h1 class="m-0">
            {{ form.name }}
          </h1>

          <h4>Agency {{ $route.meta.title }}</h4>

        </template>
      </div>
      <div class="col-md-6 text-end">
        <button
          id="settingsForm"
          type="submit"
          class="btn btn-success"
          :disabled="processing"
          @click="postForm()"
        >
          Save
        </button>
      </div>
    </div>
    <div class="row mt-3">

      <div class="col-12">
          <form
            ref="settingsForm"
            @submit.prevent="postForm()"
          >
            <div class="card">
              <div class="card-body">
                
                <div class="form-group">
                  <label for="processing_mode">Processing Mode</label>
                  <select
                    id="processing_mode"
                    v-model="form.processing_mode"
                    class="form-control text-capitalize"
                    :disabled="processing"
                  >
                    <option
                      v-for="processing_mode in processing_modes"
                      :key="processing_mode.key"
                      :value="processing_mode.key"
                    >
                      {{ processing_mode.title }}
                    </option>
                  </select>
                </div>

                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="name">Agency Name</label>
                      <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        class="form-control"
                        required
                      >
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="reference">Agency Reference</label>
                      <input
                        id="reference"
                        v-model="form.reference"
                        type="text"
                        class="form-control"
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="website">Agency Website</label>
                      <input
                        id="website"
                        v-model="form.website"
                        type="text"
                        class="form-control"
                      >
                    </div>
                  </div><!--Col-->
                  <div class="col-6">
                    <div class="form-group">
                      <label for="email">Agency Email</label>
                      <input
                        id="email"
                        v-model="form.email"
                        type="email"
                        class="form-control"
                      >
                    </div>
                  </div><!--Col-->
                </div>

                <div class="form-group">
                  <label for="agency_address">Agency Address</label>
                  <input
                    id="agency_address"
                    v-model="form.address"
                    type="text"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="agency_address_2">Address 2</label>
                  <input
                    id="agency_address_2"
                    v-model="form.address_2"
                    type="text"
                    class="form-control"
                  >
                </div>

                <div class="row">

                  <div class="col-sm-12 col-md-6">

                    <div class="form-group">
                      <label for="agency_city">City</label>
                      <input
                        id="agency_city"
                        v-model="form.city"
                        type="text"
                        class="form-control"
                      >
                    </div>

                  </div><!--Col-->

                  <div class="col-sm-12 col-md-6">

                  <div class="form-group">
                    <label for="agency_state">State</label>
                    <select
                      id="agency_state"
                      v-model="form.state"
                      class="form-control"
                      :disabled="processing"
                    >
                      <option value="" />
                      <option
                        v-for="(state, state_index) in states"
                        :key="'state_'+state_index"
                        :value="state.key"
                      >
                        {{ state.label }}
                      </option>
                    </select>
                  </div>

                  </div><!--Col-->

                </div><!--Row-->


                <div class="row">

                  <div class="col-sm-12 col-md-6">

                    <div class="form-group">
                      <label for="agency_zip">Zip</label>
                      <input
                        id="agency_zip"
                        v-model="form.zip"
                        type="text"
                        class="form-control"
                      >
                    </div>

                  </div><!--Col-->

                  <div class="col-sm-12 col-md-6">

                    <div class="form-group">
                      <label for="agency_county">County</label>
                      <input
                        id="agency_county"
                        v-model="form.county"
                        type="text"
                        class="form-control"
                      >
                    </div>

                  </div><!--Col-->

                </div><!--Row-->

                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group">
                      <label for="latitude">Latitude</label>
                      <input
                          id="latitude"
                          v-model="form.latitude"
                          type="text"
                          class="form-control"
                          :disabled="processing"
                      >
                    </div>
                  </div><!--Col-->

                  <div class="col-sm-12 col-md-6">
                    <div class="form-group">
                      <label for="longitude">Longitude</label>
                      <input
                          id="longitude"
                          v-model="form.longitude"
                          type="text"
                          class="form-control"
                          :disabled="processing"
                      >
                    </div>
                  </div><!--Col-->

                </div><!--Row-->

                <div class="form-group">
                  <label for="timezone">Time Zone</label>
                  <select
                    id="timezone"
                    v-model="form.timezone"
                    class="form-control"
                  >
                    <option
                      v-for="timezone in timezones"
                      :key="timezone.key"
                      :value="timezone.key"
                    >
                      {{ timezone.label }}
                    </option>
                  </select>
                </div>


                <div class="row my-3">

                  <div class="col-sm-12 col-md-6">

                    <div class="form-group">
                      <label for="logo">Logo URL</label>
                      <input id="logo" type="text" class="form-control" v-model="form.logo">
                    </div>

                  </div><!--Col-->

                  <div class="col-sm-12 col-md-6">

                    <div class="form-group">
                      <label for="icon">Icon URL</label>
                      <input id="icon" type="text" class="form-control" v-model="form.icon">
                    </div>

                  </div><!--Col-->

                </div><!--Row-->

                <h4>Payments</h4>

                <div class="row">
                  <div class="col-sm-12 col-md-4">
                    <div class="form-group">
                      <label for="default_issue_date">Default Issue Date</label>
                      <input
                        id="default_issue_date"
                        v-model="form.default_issue_date"
                        type="number"
                        class="form-control"
                      >
                      <p class="text-muted">Number of days from the created date</p>
                    </div>
                  </div><!--Col-->
                  <div class="col-sm-12 col-md-4">
                    <div class="form-group">
                      <label for="default_due_date">Default Due Date</label>
                      <input
                        id="default_due_date"
                        v-model="form.default_due_date"
                        type="number"
                        class="form-control"
                      >
                      <p class="text-muted">Number of days from the issue date</p>
                    </div>
                  </div><!--Col-->
                  <div class="col-sm-12 col-md-4">
                    <div class="form-group">
                      <label for="default_status">Default Payment Status</label>
                      <select
                        id="default_status"
                        v-model="form.default_status"
                        class="form-control"
                      >
                      <option value="" />
                      <template v-for="status in $root.payment_statuses">
                        <option
                          :value="status"
                        >
                          {{ status }}
                        </option>
                      </template>
                      </select>
                    </div>
                    </div><!--Col-->
                </div><!--Row-->
                                

                <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

              </div>
            </div>

          </form>


              <template v-if="form && form.created_at">
                <audit-logs class="mt-3" :form="form" />
              </template>

          </div>

      </div>

      </div>
    </div>
  </div>
</template>
<script>
export default {
    
    data() {
        return {
          processing: false,
          options: {
              required_fields: []
          },
          fields: [],
          form: {},
          processing_modes: [
          {key: 'test', title: 'test mode'},
          {key: 'live', title: 'live mode'}
          ],
          timezones: [],
          tabs: [
          {key: 'general'},
          {key: 'agency_phones'},
          {key: 'agency_emails'},
          {key: 'statuses'},
          {key: 'roles'}
          ],
          business_days: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
          customField: {},
          states: [],
          phones: [],
          emails: []
        }
    },

      mounted() {

        var self = this;

        this.$eventBus.$on('settingsProcessing', function (data) {
         self.processing = data;
        });

        this.$eventBus.$on('settings', function (data) {
          self.form = {...self.form, ...data};
        });

      },

    created() {
        
        this.resetForm();
        this.setURLParams();
        this.getOptions();
        this.getFields();
        this.getStates();
        this.getSettings();
        this.getTimezones();
    },


    methods: {

    resetForm() {
      this.form = {
        timezone: 'UTC',
        fields: [],
        phone: null,
        email: null,
        website: null,
        address: null,
        address_2: null,
        city: null,
        state: null,
        county: null,
        zip: null,
        country: null,
        business_from_time: null,
        business_to_time: null,
        business_days: [],
        latitude: null,
        longitude: null,
        send_mode: null,
        virtual_link: null,
        virtual_platform: null
      }
    },


    addCustomField() {
      
      if(!this.form.fields) {
        this.form.fields = [];
      }

      var field = {};

      if(!this.fields[this.customField].name) {
        return;
      }

      field.name = this.fields[this.customField].name;
      field.key = this.fields[this.customField].key;

      this.form.fields.push(field);
    },


        getOptions () {
          this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/options.json')
          .then(response => {
              if(response && response.data) {
                  this.options = response.data;
              }
          })
      },

      getFields() {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/fields.json')
        .then(response => {
          if(response && response.data) {
            this.fields = response.data;
          }
        })
      },
    
      getTimezones() {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/timezones.json')
        .then(response => {
            if(response && response.data) {
                this.timezones = response.data;
            }
        })
    },

    getStates () {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/states.json')
      .then(response => {
        if (response && response.data) {
          this.states = response.data;

          if(this.$root.current_user && this.$root.current_user.current_agency) {
            this.form.name = this.$root.current_user.current_agency.name;
          }
        }
      })
    },

    getSettings() {
      this.processing = true;
        this.$http.get('/'+this.$route.meta.base_url)
        .then(response => {
            if(response && response.data) {
                this.form = response.data;

                this.setTitle();

                this.processing = false;
            }
        })
    },

      setTitle() {
        if(this.form.name) {
          document.title = this.form.name + ' | Agency ' + this.$route.meta.title;
        }
      },

        postForm() {

            this.processing = true;

            this.$http.post('/'+this.$route.meta.base_url, this.form)
            .then(response => {
                if(response && response.data) {

                    this.processing = false;

                    this.$root.agency = response.data;

                    this.$eventBus.$emit('alerts', {'message': this.$route.meta.title + ' updated'});

                }
            })
        },


    }
}
</script>