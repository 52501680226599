<template>
  <div id="app">
    <div class="container">
      <logo-bar />
      <div class="row justify-content-center">
        <div class="col-6 mx-auto text-center">
          
            <template v-if="!processing">
              <button
                class="btn btn-primary"
                @click="getLogin"
              >
                Login to {{ $root.title }}
              </button>
            </template>
            <loader :is-loading="processing" />
            
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      processing: false,
      queries: {}
    }
  },
  created () {

    document.title = "Login to "+this.$root.title;

    if (!this.$route.query.state) {
      this.getLogin();
    }

    if (this.$route.query.state) {
      this.redirectUser();
    }
  },

  methods: {
    getLogin () {
      this.processing = true;

      if (this.$route.params.id) {
        this.queries.signin = this.$route.params.id;
      }
      
      let loginUrl = this.buildQueries(
        this.$apiURL + 'login',
        this.queries
        )

      this.$axios
      .get(loginUrl, {
        withCredentials: true
      })
      .then(response => {
        if (response && response.data) {
          window.location.href = response.data;
        }
      })
    },

    redirectUser () {
      this.processing = true;
      this.$axios
      .post(
        this.$apiURL +
        "auth/user?state=" +
        this.$route.query.state
        )
      .then((response) => {
        if(response && response.data) {

        this.processing = false;

        if (response.data.access_token) {
          localStorage.setItem("access_token", response.data.access_token);
          document.location.assign("/dashboard");
        } else {
          document.location.assign("/logout");
        }

        }
      })
    }
  }
}
</script>
