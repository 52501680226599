<template>
  <div>
      <router-link
        :to="{ 'name': 'portals.edit', 'params': {'id': uuid} }"
        :disabled="processing"
      >
        <template v-if="record.name">
          {{ record.name }}
        </template>
        <template v-else>
          {{ record.link }}
        </template>
      </router-link>
      <div
        v-if="processing"
        class="progress"
      >
        <div
          class="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          aria-valuenow="100"
          aria-valuemin="0"
          aria-valuemax="100"
          style="width: 100%"
        />
      </div>
  </div>
</template>
<script>
export default {

props: ['uuid'],

data() {
    return {
        processing: false,
        record: {},
        base_url: '/portals'
    }
},

created() {
    this.getRecord();
},

methods: {

        getRecord() {
            
            if(!this.uuid) {
                return;
            }

            this.processing = true;
            this.record = {};

            this.$http.get(this.base_url+'/'+this.uuid+'?fields=name,link')
            .then(response => {
                if(response && response.data) {
                    this.record = response.data;
                    this.processing = false;
                }
            })
        },

}

}
</script>