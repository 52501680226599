<template>
        <div class="table-responsive">
              <table class="table table-striped table-hover">

    <thead>
      <tr>
        <th><i class="fa fa-edit"></i> Edit</th>
        <template v-for="(column, index) in fields">
          <th>
            <template v-if="column.key">
              <span
                class="text-capitalize link-hover"
                @click="sortColumn(column)"
              >{{ column.label }}</span>
            </template>
  
            <template v-if="!column.key">
              <span class="text-capitalize">{{ column.label }}</span>
            </template>
  
            <template v-if="column.key == queries.sort">
              <button
                type="button"
                class="btn btn-outline-secondary btn-sm ml-1"
                :disabled="processing"
                @click="updateSortDirection()"
              >
                <template v-if="queries.sortDirection == 'asc'">
                  <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                </template>
  
                <template v-if="queries.sortDirection == 'desc'">
                  <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                </template>
              </button>
            </template>
          </th>
        </template>
      </tr>
    </thead>

    <tbody>
        <template v-if="records && records.length > 0">
        <template v-for="(record, index) in records">
            <tr>
                <td><a :href="'/'+$route.meta.base_url+'/'+record.uuid"><i class="fa fa-edit"></i> Edit</a></td>
                <template v-for="(column, index) in fields">
                <td>

                  <template v-if="column.key == 'status' && record[column.key]">

                    <span :class="'text-uppercase badge ' + setStatusClass(record[column.key])">
                      {{ record[column.key] }}
                    </span>

                  </template>

                  <template v-else-if="column.key == 'default'">

                    <template v-if="record[column.key]">
                      <i class="fa fa-check text-success"></i> Default
                    </template>

                    <template v-if="!record[column.key]">
                      <i class="fa fa-times text-danger"></i> Not default
                    </template>

                  </template>

                  <template v-else-if="column.key">
                  {{ record[column.key] }}
                  </template>

                  <template v-else="!column.key">
                  {{ column.label }}
                  </template>

                </td>
                </template>
            </tr>
        </template>
        </template>
    </tbody>

    </table>

    </div><!--Responsive-->

  </template>
  <script>
  export default {
  
  props: ['fields', 'queries', 'base_url', 'records'],
  
  data() {
      return {
      }
  },
  
  created() {
  },
  
  methods: {
  
      updateSortDirection() {
  
        if(this.queries.sortDirection == 'asc') {
          this.queries.sortDirection = 'desc';
        } else {
          this.queries.sortDirection = 'asc';
        }
  
        this.$eventBus.$emit('refreshRecords', this.base_url);
      },
  
      sortColumn(column) {
  
        this.queries.sort = column.key;
  
        this.updateSortDirection();
  
      },

      setStatusClass(status) {
      if(!status || status == '' || status == null) {
        return;
      }

      if(typeof status === 'number') {
        status = status.toString();
      }

      status = status.toLowerCase();


      if(status == 'successful' || status == 'activated') {
        return 'bg-success';
      }

      if(status == 'failed') {
        return 'bg-danger';
      }

      if(status == 'pending') {
        return 'bg-warning';
      }

      return 'bg-primary';
    },
  
  }
  
  }
  </script>