<template>
  <div>
    <template v-if="isLoading">
      <div class="spinner-grow text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </template>
  </div>
</template>

<script>

export default {

props: ['isLoading', 'size'],


computed: {

	spinnerSize: function() {

		var size = this.size || 'sm';

		return 'spinner-grow-'+size;
	}
}
}

</script>