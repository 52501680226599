<template>
  <div>
    
    <div class="row">
      <div class="col-lg-3 col-md-12 mb-3">

        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Search {{ $route.meta.title }}s
            </p>

            <form @submit.prevent="searchRecords">

              <div class="form-group">
                <label for="amount">Amount</label>
                <input id="amount" type="number" class="form-control" v-model="queries.amount" step="0.01" min="0">
              </div>

              <div class="form-group">
                <label for="status">Status</label>
                <select id="status" class="form-control text-uppercase" v-model="queries.status" :disabled="processing">
                  <option value=""></option>
                  <template v-for="(status, status_index) in statuses">
                    <option :key="'status_'+status_index" :value="status">{{ status }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="method">Method</label>
                <select id="method" class="form-control text-uppercase" v-model="queries.method" :disabled="processing">
                  <option value=""></option>
                  <template v-for="(method, method_index) in methods">
                    <option :key="'method_'+method_index" :value="method">{{ method }}</option>
                  </template>
                </select>
              </div>

              <template v-if="queries.method == 'card'">

                <div class="form-group">
                  <label for="brand">Card Brand</label>
                  <select id="brand" class="form-control text-uppercase" v-model="queries.brand" :disabled="processing">
                    <option value=""></option>
                    <template v-for="(brand, brand_index) in card_brands">
                      <option :key="'brand_'+brand_index" :value="brand">{{ brand }}</option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="last4">Card Last Four</label>
                  <input id="last4" type="number" class="form-control" v-model="queries.last4" min="1000" max="9999">
                </div>

              </template>

              <div class="form-group">
                <label for="min_amount">Min Amount</label>
                <input id="min_amount" type="number" class="form-control" v-model="queries.min_amount" step="0.01" min="0">
              </div>

              <div class="form-group">
                <label for="max_amount">Max Amount</label>
                <input id="max_amount" type="number" class="form-control" v-model="queries.max_amount" step="0.01" min="0">
              </div>

              <div class="form-group">
                <label for="date">Transaction Date</label>
                <input id="date" type="date" class="form-control" v-model="queries.date">
              </div>

              <div class="form-group">
                <label for="from_date">Transaction From Date</label>
                <input id="from_date" type="date" class="form-control" v-model="queries.from_date">
              </div>

              <div class="form-group">
                <label for="to_date">Transaction To Date</label>
                <input id="to_date" type="date" class="form-control" v-model="queries.to_date">
              </div>

              <div class="btn-group">

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>

              <input type="reset" class="btn btn-secondary">

              </div>

            </form>
          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->
      <div id="main" class="col-lg-9 col-md-12">

        <div class="card">
          <div class="card-body">

            <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
            </p>

            <data-table :fields="columns" :queries="queries" :base_url="$route.meta.base_url" :records="records" />

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->


        <template v-if="records && records.length < total">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary d-print-none"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->

  </div>
</template>
<script>
export default {
  
  data () {
    return {
      records: [],
      downloads: [],
      processing: false,
      downloading: false,
      total: 0,
      queries: {},
      form: {},
      columns: [
          {key: 'amount', label: 'Amount'},
          {key: 'payment', label: 'Payment'},
          {key: 'status', label: 'Status'},
          {key: 'client', label: 'Client'},
          {key: 'method', label: 'Method'},
          {key: 'pay_data', label: 'Transaction'},
          {key: 'created_at', label: 'Created'}
      ],
      methods: ['card', 'ach', 'paypal', 'applepay', 'googlepay', 'manual'],
      statuses: ['succeeded', 'viewed', 'failed', 'refunded'],
      card_brands: ['visa', 'mastercard', 'amex', 'discover', 'jcb', 'diners'],
    }
  },

  mounted() {

    var self = this;

    this.$eventBus.$on('transactionCreated', function (data) {
      self.records.unshift(data);
      self.total++;
    });

    this.refreshRecords();

  },

  created () {
    this.resetRecords();
    this.resetQueries();
    this.resetForm();
    this.setURLParams();
    this.getRecords();
    this.getPlans();
  },

  methods: {

    searchRecords () {
        this.resetRecords();
        this.getRecords();
    },

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
      this.total = 0;
    },

    resetForm() {
      this.form = {
        client: null
      }
    },

    resetQueries() {
      this.queries = {
        limit: 10,
        skip: 0,
        sort: 'created_at',
        sortDirection: 'desc',
        client: null,
        amount: null,
        plan: null,
        min_amount: null,
        max_amount: null,
        date: null,
        from_date: null,
        to_date: null,
        last4: null,
        brand: null
      }
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries(this.$route.meta.base_url, this.queries));
    },

    getRecords () {

      this.processing = true;

      this.updateURL();

      this.totalRecords();

      this.$http.get(this.buildQueries(this.$route.meta.base_url, this.queries))
      .then(response => {
        if (response && response.data) {

          this.processing = false;

          if(response.data.length) {

            this.queries.skip += response.data.length;

            var self = this;

            response.data.forEach(function(data) {
              self.records.push(data);
            });

          }
        }
      })
    },

    totalRecords() {
      this.$http.get(this.buildQueries(this.$route.meta.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status === 200) {
          this.total = response.data;
          this.setTitle();
        }
      })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's';
    },

    getPlans() {
      this.$http.get('/pay_plans?fields=uuid,name&limit=100')
      .then(response => {
        if(response && response.data) {
          this.plans = response.data;
        }
      })
    },

    setStatusClass(record) {
      if(!record.status) {
        return;
      }

      if(record.status == 'succeeded') {
        return 'badge-success';
      }

      if(record.status == 'viewed') {
        return 'badge-warning';
      }

      if(record.status == 'failed') {
        return 'badge-danger';
      }

      return 'badge-secondary';
    },



  }
}
</script>