<template>
  <div>

    <page-tabs page="settings" />

    <div class="row">

      <div class="col-sm-12 col-md-3">

        <div class="card mb-3">
          <div class="card-body">

            <p class="card-title">Add {{ $route.meta.title }}</p>

              <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="name">Name</label>
                <input id="name" class="form-control" v-model="form.name" required>
              </div>


              <div class="form-group">
                <label for="address">Address</label>
                <input id="address" class="form-control" v-model="form.address" autocomplete="street-address">
              </div>

              <div class="form-group">
                <label for="address_2">Address 2</label>
                <input id="address_2" class="form-control" v-model="form.address_2" autocomplete="address-line2">
              </div>

              <div class="form-group">
                <label for="city">City</label>
                <input id="city" class="form-control" name="city" v-model="form.city" autocomplete="address-level2">
              </div>

              <div class="form-group">
                <label for="county">County</label>
                <input id="county" class="form-control" name="county" v-model="form.county" autocomplete="address-level3">
              </div>

              <div class="form-group">
                <label for="state">State</label>
                <input id="state" class="form-control" v-model="form.state" autocomplete="address-level1">
              </div>

              <div class="form-group">
                <label for="zip">Zip</label>
                <input id="zip" class="form-control" v-model="form.zip" autocomplete="postal-code">
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Add</button>

              </form>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
          <div class="card-body">

            <p class="card-title">Search {{ $route.meta.title }}s</p>

            <form @submit.prevent="searchRecords()">

              <div class="form-group">
                <label for="search">Search</label>
                <input id="search" type="search" class="form-control" v-model="queries.search">
              </div>

              <div class="form-group">
                <label for="name">Name</label>
                <input id="name" type="text" class="form-control" v-model="queries.name">
              </div>

              <div class="form-group">
                <label for="reference">Reference</label>
                <input id="reference" type="text" class="form-control" v-model="queries.reference">
              </div>

              <div class="form-group">
                <label for="address">Address</label>
                <input id="address" type="search" class="form-control" v-model="queries.address">
              </div>

              <div class="form-group">
                <label for="created_by">Creator</label>
                <select 
                  id="created_by"
                  v-model="queries.created_by"
                  class="form-control" 
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-for="(user, index) in users">
                    <option 
                      :key="'user_'+index" 
                      :value="user.email"
                    >
                      {{ user.name }} {{ user.email }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="search_default"
                    v-model="queries.default"
                    type="checkbox"
                    class="custom-control-input"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="search_default"
                >Is Default</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="portal"
                  v-model="queries.portal"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="portal"
                >Portal</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="trashed"
                  v-model="queries.trashed"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="trashed"
                >Trashed</label>
              </div>

              <button type="submit" class="btn btn-primary" :disabled="processing">Search</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->

      <div id="main" class="col-sm-12 col-md-9">

        <div
            id="map"
            class="map-xl mb-3"
        />

        <div class="card">
          <div class="card-body">

            <p class="card-title">
                    {{ records.length }} of {{ total.toLocaleString() }} {{ $route.meta.title }}s
            </p>

            <data-table :fields="columns" :queries="queries" :base_url="$route.meta.base_url" :records="records" />

            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords()"
              >
                Load more
              </button>
            </div>
          </div><!--Row-->
        </template>

      </div><!--Col-->

    </div><!--Row-->

    <template v-if="total && total >= 1">

    <div class="row mt-3" :hidden="!records || !records.length">
      <div class="col-12 text-end">
        <button type="button" class="btn btn-danger" @click="trashRecords()" :disabled="processing">Trash {{ $route.meta.title }}s</button>
      </div><!--Col-->
    </div><!--Row-->

    </template>

  </div>
</template>
<script>
import { autofill } from '@mapbox/search-js-web';

export default {

    data() {
        return {
            records: [],
            processing: true,
            total: 0,
            queries: {},
            form: {},
            columns: [
            { key: 'name', label: 'Name'},
            { key: 'location_reference', label: 'Reference'},
            { key: 'portal', label: 'Portal'},
            { key: 'default', label: 'Default'},
            { key: 'address', label: 'Address'},
            { key: 'phone', label: 'Phone'},
            { key: 'website', label: 'Website'},
            { key: 'created_by', label: 'Creator'},
            { key: 'created_at', label: 'Created'},
            { key: 'updated_at', label: 'Updated'}
            ],
            users: [],
            autofill: {},
            downloading: false,
            downloads: [],
            map: {},
            markers: {}
        }
    },

    mounted() {

        this.initMap();

        var self = this;

        this.$eventBus.$on("newLocation", function (record) {
              self.pushRecord(record);
        });

        this.autoFill();


        this.refreshRecords();

    },

    created() {
      this.resetForm();
      this.resetRecords();
      this.resetQueries();
      this.setURLParams();
      this.getRecords();
      this.getUsers();
    },

    methods: {


        resetForm() {
          this.form = {
            name: null,
            address: null,
            address_2: null,
            city: null,
            state: null,
            zip: null,
            county: null,
            country: null,
            lat: null,
            lng: null,
            portal: 1,
            default: 0
          }
        },

        resetQueries() {
          this.queries = {
              limit: 100,
              skip: 0,
              created_by: null,
              search: null,
              name: null,
              reference: null,
              address: null,
              portal: false,
              trashed: false,
              archived: false,
              default: null,
              sort: 'name',
              sortDirection: 'asc'
          }
        },

        autoFill() {

          var options = { language: 'en', country: 'us' };

          if(this.$root.current_user && this.$root.current_user.current_agency && this.$root.current_user.current_agency.settings) {
            options['country'] = this.$root.current_user.current_agency.settings['country'] || 'us';

            if(this.$root.current_user.current_agency.settings['latitude'] && this.$root.current_user.current_agency.settings['longitude']) {
              options['proximity'] = [this.$root.current_user.current_agency.settings['longitude'], this.$root.current_user.current_agency.settings['latitude']];
            }
          }

          this.autofill = new autofill({
            accessToken: this.$root.mapbox_token,
            options: options
          });

          var self = this;
 
          this.autofill.addEventListener('retrieve', (event) => {

            if (!event.detail || !event.detail.features || !event.detail.features.length) {
              return;            
            }

            const features = event.detail.features[0];

            if(features['text_en'] && !self.form.name) {
              self.form.name = event.detail.features[0]['text_en'];
            }

            if(features['geometry'] && features['geometry']['coordinates']) {
              self.form.lat = features['geometry']['coordinates'][1] || null;
              self.form.lng = features['geometry']['coordinates'][0] || null;
            }

            if(!features['properties']) {
              return;
            }

            if(features['properties']['district']) {
              self.form.county = features['properties']['district'];
            }

            if(features['properties']['country_code']) {
              self.form.country = features['properties']['country_code'];
            }

            if(features['properties']['address_line2']) {
                self.form.address_2 = features['properties']['address_line2'];
            }

            if(features['properties']['postcode']) {
                self.form.zip = features['properties']['postcode'];
            }

            if(features['properties']['country']) {
              self.form.country = features['properties']['country'];
            }

          });

        },

        getTotal() {
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
            .then(response => {
                if(response && response.status) {
                    this.total = response.data;
                    this.setTitle();
                }
            })
        },

        updateURL() {
          history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
        },

        setTitle() {
          document.title = this.total + ' ' + this.$route.meta.title + 's | '+this.$root.title;
        },

        getRecords() {

            this.processing = true;

            this.updateURL();
            this.getTotal();

            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
            .then(response => {
                if(response && response.data) {
                    if(response.data.length) {

                      if(!this.records || !this.records.length) {
                        this.records = [];
                      }

                      var self = this;

                        response.data.forEach(function(data) {
                            self.records.push(data);
                            self.setMarker(data);
                        });
                    }

                    this.queries.skip = this.records.length;
                    this.processing = false;
                }
            })
        },

        resetRecords() {
          this.records = [];
          this.queries.skip = 0;
          this.total = 0;
          this.downloads = [];
        },

        searchRecords() {
            this.resetRecords();
            this.getRecords();
        },

        updateSortDirection() {

          if(this.queries.sortDirection == 'asc') {
            this.queries.sortDirection = 'desc';
          } else {
            this.queries.sortDirection = 'asc';
          }

          this.resetRecords();
          this.getRecords();
        },

        sortColumn(column) {

          this.queries.sort = column.key;

          this.updateSortDirection();
        },

        getUsers() {
          this.$http.get('/users?limit=1000&fields=name,email&sort=name&sortDirection=asc')
          .then(response => {
            if(response && response.data) {
              this.users = response.data;
            }
          })
        },

        postForm() {
          this.processing = true;
          this.$http.post('/'+this.$route.meta.base_url, this.form)
          .then(response => {
            if(response && response.data) {

              this.resetForm();

              this.processing = false;

              if(response.status === 201) {
                this.$router.push('/'+this.$route.meta.base_url+'/'+response.data.uuid);
              }

            }
          })
        },

        pushRecord(data)
        {
            this.$eventBus.$emit('alerts', {message: this.$route.meta.title + ' added', type: 'success'});
            this.total++;
            this.records.unshift(data);
            this.setMarker(data);
        },

        copyToClipboard(value) {
            navigator.clipboard.writeText(value);
        },

      trashRecords() {
          this.processing = true;
          this.$http.get('/'+this.$route.meta.base_url+'?limit=1000&fields=uuid')
              .then(response => {
                if(response && response.data) {

                  response.data.forEach(function(data) {
                    this.$http.delete('/'+this.$route.meta.base_url+'/'+data.uuid);
                    this.records.splice(this.records.findIndex((obj => obj.uuid === data.uuid)), 1);
                    this.total--;
                    this.setTitle();
                    this.$eventBus.$emit('alerts', {message: this.$route.meta.title + ' trashed', type: 'success'});
                  }.bind(this));

                  setTimeout(function() {
                    this.processing = false;
                  }.bind(this), 1000);
                }
              })
      },

      setMarker(record) {

          if(!this.markers) {
            return;
          }

        if(!record.lat || !record.lng) {
          return;
        }

        var popup = '<h6 class="m-0">Location <a href='+'/locations/'+record.uuid+'>'+record.name+'</a></h6>';

        if(record.default === 1) {
          popup = popup + ' <br><span class="badge badge-success">DEFAULT</span>';
        }

        if(record.phone) {
          popup = popup + '<br><i class="fa fa-phone"></i> '+record.phone;
        }

        if(record.address) {
          popup = popup + '<br><i class="fa fa-map-marker-alt"></i> '+record.address;
        }

        var marker = window.L.marker([record.lat, record.lng]).bindPopup(popup);

        this.markers.addLayer(marker);

      },

      initMap() {

        this.map = this.$leaflet.map("map").setView(this.$root.coordinates, 4);

        this.$leaflet.tileLayer(this.$root.map_layer).addTo(this.map);

      },

    }
}

</script>