<template>
  <div>

<template v-if="currentTabs && currentTabs.length">

<nav class="nav nav-tabs mb-3 d-print-none" data-html2canvas-ignore>

    <template v-for="(tab, tab_index) in currentTabs">

      <router-link
        :key="'page_tab_'+tab_index"
        :to="{ name: tab+'.index' }"
        class="nav-link text-capitalize"
        :class="setTabStyle(tab)"
      >
        {{ tab.replace("_", " ", tab) }}
      </router-link>

    </template>

</nav>

</template>

  </div>
</template>
<script>
export default {

props: ['page'],

data() {
    return {
      tabs: {
        "payments": ['payments', 'transactions', 'pay_links', 'pay_plans', 'pay_methods', 'recurring_payments', 'gift_cards', 'terminals'],
        "uploads": ['uploads', 'upload_templates', 'file_templates'],
        "portals": ['portals', 'files', 'posts', 'forms', 'submissions', 'qrs', 'redirects'],
        "reports": ['reports', 'dashboards'],
        "settings": ['settings', 'agencies', 'users', 'portals', 'locations', 'terminals']
      }
    }
},

computed: {

  currentTab: function() {
    var location = window.location;

    return location.pathname.replace("/", "");
  },

  currentTabs: function() {
    if(this.tabs[this.page]) {
      return this.tabs[this.page]
    }

    return "";
  }

},

created() {
},

methods: {

  setTabStyle(tab) {
    if(this.currentTab == tab) {
      return 'active';
    }
  }


}

}
</script>