export default {


methods: {


buildQueries(url, queries) {

    var filters = ""

    for(const query in queries) {

        if(queries[query]) {
            filters += encodeURIComponent(query) + "=" + encodeURIComponent(queries[query]) + "&";
        }
        
    }

    if(filters.length) {
        filters = filters.substring(0, filters.length-1);
    }

    return url + "?" + filters
},

setURLParams() {
    var searchParams = new URLSearchParams(window.location.search);

    var self = this;

    searchParams.forEach(function(value, key) {
        self.queries[key] = value;
    });
},

    isUUID(uuid) {

      const regex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

      return regex.test(uuid);
    },


    refreshRecords() {

        var self = this;

        this.$eventBus.$on('refreshRecords', function(view) {

            if(view !== self.$route.meta.base_url) {
              return;
            }
  
            self.resetRecords();
  
            self.getRecords();
  
        });
    }

}

}