<template>
  <div>

    <page-tabs page="payments" />
    
    <div class="row">
      <div class="col-sm-12 col-md-3">

        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Search {{ $route.meta.title }}s
            </p>

            <form @submit.prevent="searchRecords">

              <div class="form-group">
                <label for="type">Type</label>
                <select id="type" class="form-control text-uppercase" v-model="queries.type" :disabled="processing">
                  <option value=""></option>
                  <template v-for="(method_type, type_index) in $root.pay_methods">
                    <option :key="'method_type_'+type_index" :value="method_type">{{ method_type }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="brand">Brand</label>
                <select id="brand" class="form-control text-uppercase" v-model="queries.brand" :disabled="processing">
                  <option value=""></option>
                  <template v-for="(method_brand, type_index) in $root.card_brands">
                    <option :key="'method_brand_'+type_index" :value="method_brand">{{ method_brand }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="last4">Last 4</label>
                <input id="last4" type="text" class="form-control" v-model="queries.last4">
              </div>

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>

            </form>
          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->
      <div class="col-sm-12 col-md-9">

        <div class="card">
          <div class="card-body">

            <p class="card-title">
              {{ records.length }} of {{ total.toLocaleString() }} {{ $route.meta.title }}s
            </p>

            <data-table :fields="columns" :queries="queries" :base_url="$route.meta.base_url" :records="records" />

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->


        <template v-if="records && records.length < total">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary d-print-none"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->

  </div>
</template>
<script>
export default {
  
  data () {
    return {
      records: [],
      processing: false,
      total: 0,
      queries: {},
      form: {},
      columns: [
          {key: 'type', label: 'Type'},
          {key: 'brand', label: 'Brand'},
          {key: 'client', label: 'Client'},
          {key: 'last4', label: 'Last 4'},
          {key: 'exp_month', label: 'Exp Month'},
          {key: 'exp_year', label: 'Exp Year'},
          {key: 'created_by', label: 'Creator'},
          {key: 'created_at', label: 'Created'},
          {key: 'updated_at', label: 'Updated'}
      ]
    }
  },

  mounted() {
    this.refreshRecords();
  },

  created () {
    this.resetRecords();
    this.resetQueries();
    this.resetForm();
    this.setURLParams();
    this.getRecords();
  },

  methods: {

    searchRecords () {
        this.resetRecords();
        this.getRecords();
    },

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
      this.total = 0;
    },

    resetForm() {
      this.form = {
        client: null
      }
    },

    resetQueries() {
      this.queries = {
        limit: 10,
        skip: 0,
        sort: 'created_at',
        sortDirection: 'desc',
        client: null,
        brand: null,
        type: null,
        last4: null
      }
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries(this.$route.meta.base_url, this.queries));
    },

    getRecords () {

      this.processing = true;

      this.updateURL();

      this.totalRecords();

      this.$http.get(this.buildQueries(this.$route.meta.base_url, this.queries))
      .then(response => {
        if (response && response.data) {

          this.processing = false;

          if(response.data.length) {

            this.queries.skip += response.data.length;

            var self = this;

            response.data.forEach(function(data) {
              self.records.push(data);
            });

          }
        }
      })
    },

    totalRecords() {
      this.$http.get(this.buildQueries(this.$route.meta.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status === 200) {
          this.total = response.data;
          this.setTitle();
        }
      })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's';
    }


  }
}
</script>
