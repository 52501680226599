<template>
  <div>

    <div class="row">
      <div class="col-lg-6 col-sm-12">
        <div class="card">
          <div class="card-body">

    <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="name">Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="amount">Default Amount</label>
                <input
                  id="amount"
                  v-model="form.amount"
                  type="text"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="frequency">Frequency</label>
                <select id="frequency" class="form-control" v-model="form.frequency" required>
                  <template v-for="(frequency, frequency_index) in frequencies">
                    <option :value="frequency">{{ frequency }}</option>
                  </template>
                </select>
              </div>

         

                  <div class="form-group">
                    <label for="payment_location">Location

                <template v-if="form.location">
                  <a
                  :href="'/locations/'+form.location"
                  target="_blank"
                  data-tooltip="Edit Location">
                  <i class="fa fa-edit"></i> <span class="sr-only">Edit Location</span>
                  </a>
                </template>

                    </label>
                    <select
                      id="payment_location"
                      v-model="form.location"
                      class="form-control"
                      :disabled="processing"
                    >
                      <option value=""></option>
                      <template v-for="location in locations">
                        <option
                          :value="location.uuid"
                        >
                          {{ location.name }}
                        </option>
                      </template>
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="payment_portal">Portal

                    <template v-if="form.portal">
                      <a
                      :href="'/portals/'+form.portal"
                      target="_blank"
                      data-tooltip="Edit Portal">
                      <i class="fa fa-edit"></i> <span class="sr-only">Edit Portal</span>
                      </a>
                    </template>
                    
                    </label>
                    <select
                      id="payment_portal"
                      v-model="form.portal"
                      class="form-control"
                      :disabled="processing"
                    >
                      <option value="">
                        Choose Portal
                      </option>
                      <template v-for="portal in portals">
                        <option
                          :value="portal.uuid"
                        >
                          {{ portal.name }}
                        </option>
                      </template>
                    </select>
                  </div>
    </form>

          </div><!--Body-->
        </div><!--Card-->

        <audit-logs class="mt-3" :form="form" />

      </div><!--Col-->

      <div class="col-sm-12 col-lg-6">

        <template v-if="records && !records.length && !searching">
          <div class="alert alert-warning">No recurring payments found.</div>
        </template>

        <div class="card">
          <div class="card-body">

            <p class="card-title">Add Recurring Client Payment</p>

          <form @submit.prevent="postClientRecurring()">

          <div class="form-group">
            <label for="recurring_amount">Amount</label>
            <div class="input-group">
              <span
                class="input-group-text"
                style="border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important;"
              >$</span>
              <input
                id="recurring_amount"
                v-model="recurringForm.amount"
                type="number"
                class="form-control"
                min="0"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="form-group">
            <label for="start_date">Start Date</label>
            <input id="start_date" type="date" class="form-control" v-model="recurringForm.start_date">
          </div>

          <div class="form-group">
            <label for="end_date">End Date</label>
            <input id="end_date" type="date" class="form-control" v-model="recurringForm.end_date">
          </div>

          <button type="submit" class="btn btn-success" :disabled="processing || searching || !queries.client">Add</button>

          </form>

          </div><!--Body-->
        </div><!--Card-->

        <ul class="list-group mt-3">

        <template v-for="(record, client_recurring_index) in records">
          <li class="list-group-item">

            <a :href="'/client_recurrings/'+record.uuid">
            ${{ record.amount }}

            <template v-if="record.start_date">
              Start Date: {{ record.start_date | date }}
            </template>

            <template v-if="record.end_date">
              End Date: {{ record.end_date | date }}
            </template>
            </a>

            <template v-if="record.client">
              <client-button :uuid="record.client" />
            </template>

          </li>
        </template>

        </ul>

      </div><!--Col-->

    </div><!--Row-->
  </div>
</template>
<script>
export default {

  data() {
    return {
      processing: false,
      searching: false,
      form: {},
      recurringForm: {},
      queries: {},
      frequencies: ['week', 'biweek', 'month', 'quarter', 'year'],
      portals: [],
      locations: [],
      total: 0,
      client: {},
      records: []
    }
  },

  created() {
    this.resetQueries();
    this.resetRecords();
    this.resetForm();
    this.getRecord();
    this.getPortals();
    this.getLocations();
  },

  methods: {

    resetQueries() {
      this.queries = {
        client: null,
        recurring: null
      }
    },

    resetForm() {
      this.form = {
        name: null,
        description: null,
        amount: 0,
        start_date: null,
        end_date: null,
        frequency: null,
        location: null,
        portal: null
      }

      this.resetRecurringForm();
    },

    resetRecurringForm() {
      this.recurringForm = {
        client: null,
        amount: this.form.amount || 0,
        recurring: null,
        start_date: null,
        end_date: null
      }
    },

    getRecord() {

        this.processing = true;

        this.$http.get(this.$route.meta.base_url+'/'+this.$route.params.id)
        .then(response => {
            if(response && response.data) {
                
                this.form = response.data;

                this.processing = false;

                if(this.form && !this.recurringForm.amount) {
                  this.recurringForm.amount = this.form.amount;
                }

                if(!this.recurringForm.start_date) {
                  this.recurringForm.start_date = this.$moment(Date.now()).format('YYYY-MM-DD');
                }

                this.getRecords();
            }
        })
    },

    postForm() {

      this.processing = true;

      this.$http.post(this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.data.updated_at) {
            this.form.updated_at = response.data.updated_at;
          }

        }
      })
    },


    restoreRecord() {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/restore')
      .then(response => {
        if(response && response.data) {
              
              this.processing = false;

              if(response.status === 200) {
                this.form.deleted_at = null;
                this.form.archived_at = null;
                this.form.updated_at = new Date().toISOString();
              }

        }
      })
    },


  trashRecord () {
      this.processing = true;
      this.$http.delete(this.$route.meta.base_url + '/' + this.form.uuid)
      .then((response) => {
        if(response && response.status) {
          this.processing = false;

          if(response.status === 204) {
            this.form.deleted_at = new Date().toISOString();
          }
        }
      })
  },

    getLocations () {
        this.$http.get('/locations?limit=1000&sort=default&sortDirection=asc&fields=uuid,name')
        .then(response => {
            if (response && response.data) {
                this.locations = response.data;

                if(this.locations.length > 0 && !this.form.location) {
                  this.form.location = this.locations[0].uuid;
                }
            }
        })
    },

    getPortals () {
        this.$http.get('/portals?limit=1000&sort=default&sortDirection=asc&fields=uuid,name,link')
        .then(response => {
            if (response && response.data) {
                this.portals = response.data;

                if(this.portals.length > 0 && !this.form.portal) {
                  this.form.portal = this.portals[0].uuid;
                }
            }
        })
    },

    getRecords() {

      this.queries.recurring = this.form.uuid;

      this.searching = true;

      this.$http.get(this.buildQueries('/client_recurrings', this.queries))
      .then(response => {
        if(response && response.data) {
          this.records = response.data;
          this.searching = false;
        }
      })
    },

    resetRecords() {
      this.records = [];
    },

    postClientRecurring() {
      this.processing = true;

      this.recurringForm.recurring = this.form.uuid;

      if(this.client && this.client.uuid) {
        this.recurringForm.client = this.client.uuid;
      }

      this.$http.post('/client_recurrings', this.recurringForm)
      .then(response => {
        if(response && response.data) {
          this.records.unshift(response.data);
          this.resetRecurringForm();
        }
      })
    },

  }
}
</script>