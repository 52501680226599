<template>
  <div>

    <div class="row">
      <div class="col-sm-12 col-md-3">

        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Add {{ $route.meta.title }}
            </p>

            <form
                @submit.prevent="postForm"
            >

              <div class="form-group">
                <div class="custom-control custom-switch">
                  <input
                      id="self_chosen"
                      v-model="form.self_chosen"
                      type="checkbox"
                      class="custom-control-input"
                      :disabled="processing"
                  >
                  <label
                      class="custom-control-label"
                      for="self_chosen"
                  >Self-Chosen Brand</label>
                </div>
              </div>

              <template v-if="form.self_chosen">
                <div class="form-group">
                  <label for="amount">Amount</label>
                  <div class="input-group">
                    <span
                        class="input-group-text"
                        style="border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important;"
                    >$</span>
                    <input
                        id="amount"
                        v-model="form.amount"
                        type="number"
                        class="form-control"
                        @change="resetNewForm()"
                        step="1"
                    >
                  </div>
                </div>
              </template>

              <div class="form-group" :hidden="form.self_chosen !== false">
                <label for="brand">Brand</label>
                <select id="brand" class="form-control" v-model="form.brand" @change="resetNewForm()">
                  <option value=""></option>
                  <option v-for="gift_card in gift_cards" :value="gift_card.name">{{ gift_card.name }}</option>
                </select>
              </div>

              <template v-if="currentCard && currentCard.id && !form.self_chosen">

                <template v-if="currentCard.type == 'range'">

                  <div class="form-group">
                    <label for="amount">Amount</label>
                    <div class="input-group">
                      <span
                        class="input-group-text"
                        style="border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important;"
                      >$</span>
                      <input
                        id="amount"
                        v-model="form.amount"
                        type="number"
                        class="form-control"
                        @change="resetNewForm()"
                        :min="currentCard.min"
                        :max="currentCard.max"
                        step="1"
                      >
                    </div>
                    <template v-if="currentCard.min && currentCard.max">
                      <p class="form-text text-muted small">${{ currentCard.min }} min. ${{ currentCard.max }} max.</p>
                    </template>
                  </div>

                </template>

                <template v-else>
                  <div class="form-group">
                  <label for="amount_option">Choose Amount</label>
                  <select id="amount_option" class="form-control" v-model="form.amount" @change="resetNewForm()" required>
                    <option v-for="amount in amounts" :key="amount" :value="amount">${{ amount }}.00</option>
                  </select>
                </div>
                </template>

              </template>

              <div class="form-group">
                <label for="contact">Phone or Email (optional)</label>
                <input id="contact" type="text" class="form-control" v-model="form.contact" @change="resetNewForm()">
              </div>

              <div class="form-group custom-control custom-switch mt-3">
                 <input
                     id="test_mode"
                     v-model="form.test_mode"
                     type="checkbox"
                     class="custom-control-input"
                     :disabled="processing"
                     :readonly="$root.current_region == 'staging'"
                 >
                 <label
                     class="custom-control-label"
                     for="test_mode"
                 >Test Mode</label>
               </div>


              <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
              >
                Add
              </button>
            </form>

            <template v-if="new_form && new_form.uuid">
              <router-link tag="button" :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': new_form.uuid}}" class="btn btn-primary my-3">
                Edit {{ $route.meta.title }}
              </router-link>
            </template>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">
            <p class="card-title">
              Search {{ $route.meta.title }}s
            </p>

            <form @submit.prevent="searchRecords()">
              <div class="form-group">
                <label for="search">Search</label>
                <input
                    id="search"
                    v-model="queries.search"
                    class="form-control"
                    type="search"
                >
              </div>

              <div class="form-group">
                <label for="search_contact">Contact</label>
                <input id="search_contact" type="text" class="form-control" v-model="queries.contact" placeholder="Phone or email">
              </div>

              <div class="form-group">
                <label for="search_brand">Brand</label>
                <select id="search_brand" class="form-control" v-model="queries.brand">
                  <option value=""></option>
                  <template v-if="gift_cards && gift_cards.length > 0">
                  <option v-for="search_brand in gift_cards" :key="'search_brand_'+search_brand.id" :value="search_brand.name">{{ search_brand.name }}</option>
                  </template>
                </select>
              </div>

              <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
          <div class="card-body">
            <p class="card-title">Upload Gift Cards</p>

            <p class="card-text">Bulk send gift cards with a CSV file</p>

            <form @submit.prevent="uploadFile()">

              <div class="form-group">
                <label for="upload_file">File</label>
                <input id="upload_file" type="file" class="form-control-file" accept=".csv" required>
              </div>

              <button
                  type="submit"
                  class="btn btn-success mt-3"
                  :disabled="processing"
              >
                Upload
              </button>

            </form>


          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->
      <div class="col-sm-12 col-md-9">

        <div class="card">
          <div class="card-body">

            <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
            </p>

            <data-table :fields="columns" :queries="queries" :base_url="$route.meta.base_url" :records="records" />

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->


        <template v-if="records && records.length < total">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                  type="button"
                  class="btn btn-outline-primary d-print-none"
                  :disabled="processing"
                  @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->

  </div>
</template>
<script>
export default {

  data () {
    return {
      records: [],
      processing: false,
      downloading: false,
      total: 0,
      queries: {},
      form: {},
      new_form: {},
      columns: [
        {key: 'amount', label: 'Amount'},
        {key: 'contact', label: 'Contact'},
        {key: 'brand', label: 'Brand'},
        {key: 'test', label: 'Test Mode'},
        {key: 'status', label: 'Status'},
        {key: 'created_by', label: 'Creator'},
        {key: 'created_at', label: 'Created'},
        {key: 'updated_at', label: 'Updated'}
      ],
      amounts: [15, 20, 25, 50, 75, 100],
      gift_cards: []
    }
  },

  computed: {

    currentCard() {
      if(!this.form.brand) {
        return;
      }

      var card = this.gift_cards.find(card => card.name === this.form.brand);

      if(!card) {
        return;
      }

      return card;
    }

  },

  mounted() {
    this.refreshRecords();
  },

  created () {
    this.resetRecords();
    this.resetQueries();
    this.resetForm();
    this.setURLParams();
    this.getRecords();
    this.getGiftCards();
  },

  methods: {

    searchRecords() {
      this.queries.skip = 0;
      this.resetRecords();
      this.getRecords();
    },

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
      this.total = 0;
    },

    resetForm() {
      this.form = {
        self_chosen: true,
        brand: null,
        product: null,
        amount: null,
        quantity: 1,
        contact: null,
        test_mode: true
      }

      if(localStorage.getItem('settings') && !this.$root.agency) {
        this.$root.agency = JSON.parse(localStorage.getItem('settings'));
      }

      if(this.$root.agency && this.$root.agency.processing_mode == 'live') {
        this.form.test_mode = false;
      }

    },

    resetQueries() {
      this.queries = {
        limit: 10,
        skip: 0,
        sort: 'created_at',
        sortDirection: 'desc',
        search: null,
        contact: null,
        brand: null
      }
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries(this.$route.meta.base_url, this.queries));
    },

    getRecords () {

      this.processing = true;

      this.updateURL();

      this.totalRecords();

      this.$http.get(this.buildQueries(this.$route.meta.base_url, this.queries))
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.data.length) {

                this.queries.skip += response.data.length;

                var self = this;

                response.data.forEach(function(data) {
                  self.records.push(data);
                });

              }
            }
          })
    },

    totalRecords() {
      this.$http.get(this.buildQueries(this.$route.meta.base_url+'/total', this.queries))
          .then(response => {
            if(response && response.status === 200) {
              this.total = response.data;
              this.setTitle();
            }
          })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's';
    },

    postForm() {
      this.processing = true;

      this.new_form = null;

      if(this.currentCard && this.currentCard.id && !this.form.self_chosen) {
        this.form.product = this.currentCard.id;
      }

      this.$http.post(this.$route.meta.base_url, this.form)
          .then(response => {
            if(response && response.status) {

              if(response.status === 201) {
                this.resetForm();
                this.total++;
                this.setTitle();
                this.records.unshift(response.data);
                this.new_form = response.data;
                this.$eventBus.$emit('alerts', {message: this.$route.meta.title + ' added', type: 'success'});
              }

              this.processing = false;

            }
          });
    },

    resetNewForm() {
      this.new_form = null;
    },


    getGiftCards () {
      this.$axios.get(this.$root.assets_url+'js/gift_cards.json')
      .then(response => {
          if(response && response.data) {
            this.gift_cards = response.data;
          }
      })
    },

    uploadFile() {

      //read CSV file
      var file = document.getElementById('upload_file').files[0];

      if(!file) {
        console.log('No file selected');
        return;
      }

      var reader = new FileReader();

      //parse file and upload each individually as post request

      //read header row and skip first row
      reader.onload = function(e) {
        var rows = e.target.result.split('\n');

        for(var i = 1; i < rows.length; i++) {
          var row = rows[i].split(',');

          if(row.length < 2) {
            continue;
          }

          let data = {
            brand: row[0],
            amount: row[1],
            quantity: row[2] || 1,
            contact: row[3] || null
          }

          if(!data.brand) {
            continue;
          }

          var card = this.gift_cards.find(card => card.name === data.brand);

          if(card) {
            data.product = card.id;
          }

          data.test_mode = true;

          this.$http.post(this.$route.meta.base_url, data)
          .then(response => {
            if(response && response.status === 201) {
              this.records.unshift(response.data);
              this.total++;
              this.setTitle();
              document.getElementById('upload_file').value = '';
            }
          })
        }
      }

    }


  }
}
</script>
