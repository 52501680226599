<template>
  <div>

    <div class="row">

      <div class="col-12 mx-auto">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="postForm()">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="name">Name</label>
                    <input
                      id="name"
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      required
                    >
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="email">Email</label>
                    <input
                      id="email"
                      v-model="form.email"
                      type="email"
                      class="form-control"
                      required
                      readonly
                    >
                  </div>
                </div><!--Col-->

              </div><!--Row-->
              
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="phone">Phone</label>
                    <input
                      id="phone"
                      v-model="form.phone"
                      type="tel"
                      class="form-control"
                    >
                  </div>
                </div><!--Col-->

                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="phone_ext">Extension</label>
                    <input
                      id="phone_ext"
                      v-model="form.phone_ext"
                      class="form-control"
                    >
                  </div>
                </div><!--Col-->
              </div>

              <div class="form-group">
                <label for="photo">Photo URL</label>
                <input id="photo" type="text" class="form-control" v-model="form.photo">
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

            </form>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="form && form.uuid">
          <audit-logs
            class="mt-3"
            :form="form"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {

  data() {
    return {
      processing: false,
      form: {},
    };
  },

  created() {

    this.getRecord();

  },

  methods: {

    getRecord() {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
      .then((response) => {
        if(response && response.data) {
          this.processing = false;
          this.form = response.data;
          this.setTitle();
        }
      })
    },

    setTitle() {
      document.title = this.form.email + ' | ' + this.$route.meta.title;
    },

    trashForm() {
      this.$http.delete('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
      .then(response => {
        if(response && response.status >= 200) {

          this.processing = false;

          this.$router.push('/'+this.$route.meta.base_url);

        }
      })
    },

    postForm() {

      this.processing = true;
      let form = {...this.form};

      delete form.email;

      this.$http.post('/'+this.$route.meta.base_url+'/'+this.form.uuid, form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.data.updated_at) {
            this.form = {...this.form, ...response.data};
          }
        }
      })
    },

    copyToClipboard(value) {
        navigator.clipboard.writeText(value);
    }

    
  }
}
</script>
