export default {

    data() {
        return {
            processing: false
        }
    },

  methods: {

    getCurrentUser () {

        this.processing = true;

      this.$http.get('/users/current')
      .then(response => {
        if (response && response.status === 200) {

          this.processing = false;

          if(response.data.uuid) {

          this.$root.user = response.data;

          }

        } else {
          this.clearUser();
        }
      })
    },

    clearUser () {
      localStorage.clear();
      delete this.$http.defaults.headers.common['Authorization'];
      this.$root.user = {};
      location.reload();
    },

  isUUID(uuid) {

      const regex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

      return regex.test(uuid);
  },

  }
}