<template>
  <div>

    <tool-bar
      :form="form"
    />

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <div class="card">
          <div class="card-body">

    <form @submit.prevent="postForm()">

    <div class="form-group">
        <label for="pay_link_name">Pay Link Name</label>
        <input id="pay_link_name" type="text" class="form-control" v-model="form.name"
        >
    </div>

      <div class="form-group">
        <label for="portal">Portal

          <template v-if="form.portal">
            <a
                :href="'/portals/'+form.portal"
                target="_blank"
                data-tooltip="Edit Portal">
              <i class="fa fa-edit"></i> <span class="sr-only">Edit Portal</span>
            </a>
          </template>

        </label>
        <select
            id="portal"
            v-model="form.portal"
            class="form-control"
            :disabled="processing"
        >
          <option value="">
            Choose Portal
          </option>
          <template v-for="portal in portals">
            <option
                :value="portal.uuid"
            >
              {{ portal.name }}
            </option>
          </template>
        </select>
      </div>

      <div class="form-group">
        <label for="location">Location

          <template v-if="form.location">
            <a
                :href="'/locations/'+form.location"
                target="_blank"
                data-tooltip="Edit Location">
              <i class="fa fa-edit"></i> <span class="sr-only">Edit Location</span>
            </a>
          </template>

        </label>
        <select
            id="location"
            v-model="form.location"
            class="form-control"
            :disabled="processing"
        >
          <option value="">
            Choose Location
          </option>
          <template v-for="location in locations">
            <option
                :value="location.uuid"
            >
              {{ location.name }}
            </option>
          </template>
        </select>
      </div>

      <div class="form-group">
        <label for="amount">Default Amount</label>
        <input id="amount" type="text" class="form-control" v-model="form.amount">
      </div>


      <div class="form-group">
        <label for="min_amount">Minimum Amount</label>
        <input id="min_amount" type="text" class="form-control" v-model="form.min_amount">
      </div>


      <div class="form-group">
        <label for="max_amount">Maximum Amount</label>
        <input id="max_amount" type="text" class="form-control" v-model="form.max_amount">
      </div>

      <p>Enabled Fields</p>
      <template v-for="field in availableFields">
        <button type="button" class="btn btn-sm" :class="setEnabledFieldClass(field)" @click="toggleField(field)" :disabled="processing">{{ field.name }}</button>
      </template>

      <template v-if="form.fields && form.fields.length > 0">
        <ul class="list-group my-3">

            <li
                v-for="field in form.fields"
                class="list-group-item"
            >
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <label :for="'enabled_fields_'+field.name">Edit {{ field.name }}</label>
                  <input
                      :id="'enabled_fields_'+field.name"
                      v-model="field.name"
                      type="text"
                      class="form-control form-control-sm"
                  >
                </div><!--Col-->

                <div class="col-sm-12 col-md-3">
                  <div class="form-group custom-control custom-switch">
                    <input
                        :id="'required_field_'+field.key"
                        v-model="field.required"
                        type="checkbox"
                        class="custom-control-input"
                    >
                    <label
                        class="custom-control-label"
                        :for="'required_field_'+field.key"
                    > Required</label>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-3 text-end">
                  <button
                      type="button"
                      class="btn btn-secondary btn-sm"
                  >
                    <i class="fa fa-bars" /> <span class="sr-only">Move</span>
                  </button>
                </div><!--Col-->
              </div><!--Row-->
            </li>
        </ul>
      </template>

      <br>

      <button type="submit" class="btn btn-success my-3" :disabled="processing">Save</button>

    </form>

          </div><!--Body-->
        </div><!--Card-->

        <audit-logs class="mt-3" :form="form" />

      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

        <div class="card">
          <div class="card-body">

            <div class="row mb-3">
              <div class="col-sm-12 col-md-6">
                <p class="card-title">Multilingual Title</p>
                <p class="card-text">Add a title in any enabled language.</p>
              </div><!--Col-->
              <div class="col-sm-12 col-md-6 text-end">
              </div><!--Col-->
            </div><!--Row-->

            <form @submit.prevent="postForm()">

              <button type="submit" class="btn btn-success" :disabled="processing" hidden>Save</button>

            </form>

          </div>
        </div>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

        <template v-if="qr_code">
          <a :href="setPortalLink()" target="_blank">
          <img
          :src="'data:image/svg+xml;base64,'+qr_code"
          class="img-fluid" alt="QR Code" width="120" height="120" />
          </a>
        </template>


          <p class="card-title"><a :href="'/payments'">{{ payments.length }} of {{ total_payments }} Payments</a></p>

          <template v-if="payments && payments.length > 0">
            <ul class="list-group">
            <template v-for="payment in payments">
              <router-link tag="li" :to="{ 'name': 'payments.edit', 'params': {'id': payment.uuid} }" class="list-group-item list-group-item-action">
                {{ payment.amount }} Due {{ payment.due_at | date }} <template v-if="payment.status"><span class="badge badge-pill" :class="setStatusClass(payment.status)">{{ payment.status }}</span></template>
              </router-link>
            </template>
            </ul>
          </template>


      </div><!--Col-->


    </div><!--Row-->

  </div>
</template>
<script>
export default {
  data() {
    return {
      processing: false,
      uploading: false,
      form: {},
      queries: {},
      portals: [],
      portal: {},
      qr_code: null,
      locations: [],
      payments: [],
      total_payments: 0,
      availableFields: [
        {key: 'contact', type: 'text', name: 'Phone or Email'},
        {key: 'reference', type: 'text', name: 'Reference'},
        {key: 'case_number', type: 'text', name: 'Case Number'},
        {key: 'description', type: 'text', name: 'Description'},
        {key: 'type', type: 'text', name: 'Payment Type'},
        {key: 'notes', type: 'text', name: 'Notes'}
      ]
    }
  },

  created() {
    this.resetForm();
    this.getRecord();
    this.getPortals();
    this.getLocations();
  },

  methods: {

    resetForm() {
      this.form = {
          amount: 0,
          min_amount: 0,
          max_amount: 0,
          name: null,
          description: {
            'en': ''
          },
          title: {
            'en': ''
          },
          portal: null,
          location: null
      }
    },

    setPortalLink() {

      if(!this.portal || !this.portal.link) {
        return;
      }

      if(!this.form || !this.form.url) {
        return;
      }

      return 'https://'+this.portal.link+'.'+this.portal.domain+'/l/'+this.form.url;
    },


    slugString(str) {

        str = str.replace(/^\s+|\s+$/g, '');
        str = str.toLowerCase();

        str = str.replace(/[^a-z0-9 -]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-'); 

        return str;
    },

    getLocations () {
        this.$http.get('/locations?limit=1000&sort=name&sortDirection=asc&fields=uuid,name')
        .then(response => {
            if (response && response.data) {
                this.locations = response.data;
            }
        })
    },

    getPortals() {
      this.$http.get('/portals?limit=100&fields=name,uuid,logo')
      .then(response => {
        if(response && response.data) {
          this.portals = response.data;
        }
      })
    },

    getPortal() {

      if(!this.form.portal) {
        return;
      }

      if(this.portal && this.portal.uuid) {
        if(this.form.portal == this.portal.uuid) {
          return;
        }
      }

      this.$http.get('/portals/'+this.form.portal)
          .then(response => {
            if(response && response.data) {
              this.portal = response.data;

              if(!this.portal.uuid) {
                return;
              }

              this.getQRCode();
            }
          })
    },

    getQRCode() {
      this.qr_code = null;
      this.$http.post('/qrs?content='+this.setPortalLink())
          .then(response => {
            if(response && response.data) {
              this.qr_code = response.data;
            }
          })
    },

    getRecord() {

        this.processing = true;

        this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
        .then(response => {
            if(response && response.data) {
                
                this.form = response.data;

                if(!response.data.fields) {
                  this.form.fields = [];
                }

                if(!response.data.description) {
                  this.form.description = {
                    'en': ''
                  }
                }

                if(!response.data.title) {
                  this.form.title = {
                    'en': ''
                  }
                }

                this.processing = false;

                this.setTitle();

                this.getPortal();

                this.getPayments();
            }
        })
    },

    setTitle() {
      document.title = this.form.name + ' | ' + this.$route.meta.title;
    },

    getPayments() {

      this.$http.get('/payments/total')
          .then(response => {
            if(response && response.status) {
              this.total_payments = response.data;
            }
          })


      this.$http.get('/payments')
          .then(response => {
            if(response && response.data) {
              this.payments = response.data;
            }
          })
    },


    postForm() {

      this.processing = true;

      this.$http.post('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.data.updated_at) {
            this.form.updated_at = response.data.updated_at;
            this.$eventBus.$emit('alerts', {message: this.$route.meta.title + ' saved', type: 'success'});
          }

        }
      })
    },


    restoreRecord() {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/restore')
      .then(response => {
        if(response && response.data) {
              
              this.processing = false;

              if(response.status === 200) {
                this.$eventBus.$emit('alerts', {message: this.$route.meta.title + ' restored', type: 'success'});
                this.form.deleted_at = null;
                this.form.archived_at = null;
                this.form.updated_at = new Date().toISOString();
              }

        }
      })
    },

    archiveRecord() {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/archive')
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                this.$eventBus.$emit('alerts', {message: this.$route.meta.title + ' archived', type: 'success'});
                this.form = {...this.form, ...response.data};
              }

            }
          })
    },

  trashRecord () {
      this.processing = true;
      this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
      .then((response) => {
        if(response && response.status) {
          this.processing = false;

          if(response.status === 204) {
            this.$eventBus.$emit('alerts', {message: this.$route.meta.title + ' trashed', type: 'success'});
            this.form.deleted_at = new Date().toISOString();
          }
        }
      })
  },

    setStatusClass(status) {
      if(status == 'outstanding') {
        return 'badge-danger';
      }

      if(status == 'paid') {
        return 'badge-success';
      }

      return 'badge-primary';
    },

    toggleField(field) {

      if(!this.form.fields) {
        this.form.fields = [];
      }

      if(_.find(this.form.fields, {'key': field.key})) {
        this.form.fields = _.reject(this.form.fields, {'key': field.key});
        return;
      }

      field.required = true;

      this.form.fields.push(field);
    },

    setEnabledFieldClass(field) {
      if(_.find(this.form.fields, {'key': field.key})) {
        return 'btn-primary';
      }

      return 'btn-secondary';
    },

  }
}
</script>