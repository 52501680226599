<template>
<div>
<h1>{{ agency.name }} Dashboard</h1>
<template v-if="$root.user && $root.user.name">
  <h4>Welcome {{ $root.user.name }}</h4>
</template>

    <div class="row">
      <div class="col-md-12">
        <h2>Payments</h2>
        <div class="row">
          <template v-if="totals && totals.total_payments >= 0">
          <div class="col">
          <div class="card">
            <div class="card-body">
              <h1>{{ totals.total_payments.toLocaleString() }}</h1>
              <h5 class="card-title">Total Payments</h5>
            </div><!--Body-->
          </div><!--Card-->
          </div><!--Col-->
          </template>
          <template v-if="totals && totals.sum_payments">
          <div class="col">
          <div class="card">
            <div class="card-body">
              <h1>{{ totals.sum_payments }}</h1>
              <h5 class="card-title">Total Payments Value</h5>
            </div><!--Body-->
          </div><!--Card-->
          </div><!--Col-->
          </template>
        </div><!--Row-->
      </div>
    </div>

    <div class="row my-3">
      <div class="col-md-12">
        <h2>Gift Cards</h2>
        <div class="row">
          <template v-if="totals && totals.total_gift_cards >= 0">
          <div class="col">
          <div class="card">
            <div class="card-body">
              <h1>{{ totals.total_gift_cards.toLocaleString() }}</h1>
              <h5 class="card-title">Total Gift Cards</h5>
            </div><!--Body-->
          </div><!--Card-->
          </div><!--Col-->
          </template>
          <template v-if="totals && totals.sum_gift_cards">
          <div class="col">
          <div class="card">
            <div class="card-body">
              <h1>{{ totals.sum_gift_cards }}</h1>
              <h5 class="card-title">Total Gift Card Value</h5>
            </div><!--Body-->
          </div><!--Card-->
          </div><!--Col-->
          </template>
        </div><!--Row-->
      </div>
    </div>

</div>
</template>
<script>
export default {

  data() {
    return {
      processing: false,
      queries: {},
      agency: {},
      totals: {
        total_gift_cards: 0,
        sum_gift_cards: 0,
        total_payments: 0,
        sum_payments: 0
      }
    }
  },

  created() {
    this.getSettings();
    this.getTotals();
  },

  methods: {

    genRandomMoney() {
      return (Math.random() * 10000).toFixed(2);
    },

    getSettings() {
      this.$http.get('/settings')
      .then(response => {
        if(response && response.data) {
          this.agency = response.data;
        }
      })
    },

    getTotals() {
      this.$http.get('/gift_cards/total')
      .then(response => {
        if(response && response.status) {
          this.totals.total_gift_cards = response.data;
        }
      })

      this.$http.get('/gift_cards/total?format=sum')
      .then(response => {
        if(response && response.status) {
          this.totals.sum_gift_cards = response.data;
        }
      })

      this.$http.get('/payments/total')
      .then(response => {
        if(response && response.status) {
          this.totals.total_payments = response.data;
        }
      })

      this.$http.get('/payments/total?format=sum')
      .then(response => {
        if(response && response.status) {
          this.totals.sum_payments = response.data;
        }
      })

    }

  }
}
</script>