import Vue from 'vue';
import moment from 'moment-timezone';

function date (value) {
  if (value && moment(new Date(value)).isValid()) {
    return moment(value, 'YYYY-MM-DD').format('MM/DD/YY');
  }
}

function time (value) {
  if (value) {
    const timeSplit = value.split(':');
    if (Number(timeSplit[0]) > 11) {
      return ((timeSplit[0] % 12) === 0 ? 12 : timeSplit[0] % 12) + ':' + timeSplit[1] + ' PM';
    } else {
      return value + ' AM';
    }
  }
  return value;
}

function timeLocal (value) {
  if (value && moment(value, 'HH:mm:ss').isValid()) {
    var date = moment.utc(value, 'HH:mm:ss');
    return date.tz(moment.tz.guess() || localStorage.getItem('timezone')).format('h:mm A z');
  }
}

function datetime (value) {
  if (value && moment(value).isValid()) {
    var date = moment.utc(value, 'YYYY-MM-DD HH:mm:ss');
    return date.tz(moment.tz.guess() || localStorage.getItem('timezone')).format('MM/DD/YY h:mm A z');
  }
}

function datetimeMil (value) {
  if (value && moment(value, 'YYYY-MM-DD HH:mm:ss:SSS').isValid()) {
    var date = moment.utc(value, 'YYYY-MM-DD HH:mm:ss:SSS');
    return date.tz(moment.tz.guess() || localStorage.getItem('timezone')).format('MM/DD/YY h:mm:ss.SSS A z');
  }
}

function datetimeSec (value) {
  if (value && moment(value, 'YYYY-MM-DD HH:mm:ss').isValid()) {
    var date = moment.utc(value, 'YYYY-MM-DD HH:mm:ss');
    return date.tz(moment.tz.guess() || localStorage.getItem('timezone')).format('MM/DD/YY h:mm:ss A z');
  }
}

function dateDay (value) {
  if (value && moment(value, 'YYYY-MM-DD').isValid()) {
    var date = moment.utc(value, 'YYYY-MM-DD');
    return date.tz(moment.tz.guess() || localStorage.getItem('timezone')).format('dddd');
  }
}

function dateTimezone (value) {
  if (value && moment(value).isValid()) {
    var date = moment.utc(value, 'YYYY-MM-DD HH:mm:ss');
    return date.tz(moment.tz.guess() || localStorage.getItem('timezone')).format('YYYY-MM-DD HH:mm:ss');
  }
}

function timeago (value) {
  if (value && moment(value).isValid()) {
    return moment(value).fromNow();
  }
  return value;
}

function rmDashes (value) {
  if (value) {
    var str = value.replace(/_/g, " ");
    return str.replace(/_/, ' ');
  }
}

function toLocaleTime (date, time) {
  var localDate = moment.utc(date + ' ' + time + ':00', 'YYYY-MM-DD HH:mm:ss')
  return localDate.tz(moment.tz.guess() || localStorage.getItem('timezone')).format('HH:mm')
}

function isValidUUID(str) {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(str);
}

function slugString(str) {
  
    str = str.replace(/^\s+|\s+$/g, '');
    str = str.toLowerCase();

    str = str.replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-'); 

    return str;
}

function parseMoney(str) {

  const cleaned = str.replace(/[^\d.,]/g, '');

  const periodIndex = cleaned.lastIndexOf('.');
  const commaIndex = cleaned.lastIndexOf(',');

  let normalized;

  if(commaIndex > periodIndex) {
    normalized = cleaned.replace(/\./g, '').replace(',', '.');
  } else {
    normalized = cleaned.replace(/,/g, '');
  }

  return parseFloat(normalized);
}

const filters = { date, datetime, dateDay, datetimeMil, datetimeSec, time, timeLocal, timeago, rmDashes, dateTimezone, toLocaleTime, isValidUUID, slugString, parseMoney }

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

export default filters
