<template>
  <div>
    <div class="container">
      <logo-bar />
      <div class="row justify-content-center">
        <div class="col-6 mx-auto text-center">
 
              <a
                class="btn btn-primary"
                href="/login"
              >Login to {{ $root.title }}</a>
            </div>
          
      </div>
    </div>
  </div>
</template>

<script>
export default {

  data () {
    return {
    }
  },

  mounted () {
    this.clearUser();
  },

  methods: {

    clearUser () {
      localStorage.clear();
      delete this.$http.defaults.headers.common['Authorization'];
      this.$root.user = null;
    }

  }
}
</script>
