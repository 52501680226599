<template>
    <div>
  
      <page-tabs :page="$route.meta.tabs" />
  
      <div class="row">
        <div class="col-sm-12 col-md-3">
          <div class="card">
            <div class="card-body">
              <p class="card-title">
                Add {{ $route.meta.title }}
              </p>
  
              <form @submit.prevent="postForm()">

                <template v-for="(field, field_index) in fields">

                  <div>

                    <template v-if="!field.type">
                    </template>

                    <template v-else-if="field.type == 'text'">

                  <div class="form-group">
                  <label :for="field.key">{{ field.label }}</label>
                  <input
                    :id="field.key"
                    :type="field.type"
                    v-model="form[field.key]"
                    class="form-control"
                    :required="field.required"
                    :hidden="field.hidden"
                    :disabled="processing"
                  >
                  </div>

                    </template>

                    <template v-if="field.type == 'select'">

                      <div class="form-group">
                <label :for="field.key">{{ field.label }}

                <template v-if="form[field.key]">
                  <a
                  :href="'/'+field.options+'/'+form[field.key]"
                  target="_blank"
                  :data-tooltip="'Edit '+field.key">
                  <i class="fa fa-edit"></i> <span class="sr-only">Edit {{ field.label }}</span>
                  </a>
                </template>
                
                </label>
                <select
                  :id="field.key"
                  v-model="form[field.key]"
                  class="form-control"
                  :disabled="processing"
                  :required="field.required"
                >
                  <option value="">
                    Choose {{ field.label }}
                  </option>
                  <template v-for="option in options[field.options]">
                    <option
                      :key="'option_'+option.uuid"
                      :value="option.uuid"
                    >
                      {{ option.name }}
                    </option>
                  </template>
                </select>
              </div>

                    </template>

                  </div>

                </template>
  
                  <button
                    type="submit"
                    class="btn btn-success"
                    :disabled="processing"
                  >
                    Add
                  </button>
  
              </form>
            </div><!--Body-->
          </div><!--Card-->

          <div class="card my-3">
            <div class="card-body">
              <p class="card-title">Search {{ $route.meta.title }}s</p>

              <form @submit.prevent="searchRecords()">
              
                <template v-for="(field, field_index) in fields">

                  <div :key="'search_'+field_index" v-show="!field.hidden">

                  <template v-if="field.type == 'text'">

                  <div class="form-group">
                    <label :for="'search_'+field.key">{{ field.label }}</label>
                    <input :id="'search_'+field.key" :type="field.type" v-model="queries[field.key]" class="form-control" :disabled="processing">
                  </div>

                  </template>

                  <template v-if="field.type == 'select'">
                    
                    <div class="form-group">
                      <label :for="'search_'+field.key">{{ field.label }}</label>
                      <select :id="'search_'+field.key" v-model="queries[field.key]" class="form-control" :disabled="processing">
                        <option value="">Choose {{ field.label }}</option>
                        <template v-for="option in options[field.options]">
                          <option :key="'search_option_'+option.uuid" :value="option.uuid">{{ option.name }}</option>
                        </template>
                      </select>
                    </div>

                  </template>

                  </div>

                </template>

                <button type="submit" class="btn btn-primary" :disabled="processing">
                  Search
                </button>

              </form>
            </div>
          </div>


        </div><!--Col-->
  
        <div class="col-sm-12 col-md-9">
          <div class="card">
            <div class="card-body">

              <p class="card-title">
                    {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
              </p>
  
              <data-table :fields="columns" :queries="queries" :base_url="$route.meta.base_url" :records="records" />

  
              <template v-if="!processing && !records.length">
                <div class="alert alert-warning">
                  No {{ $route.meta.title }}s found.
                </div>
              </template>
            </div><!--Body-->
          </div><!--Card-->
  
          <template v-if="records && records.length && total > records.length">
  
          <div class="row mt-3">
            <div class="col-12 text-center">
  
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="getRecords"
                >
                  Load more
                </button>
  
            </div><!--Col-->
          </div><!--Row-->
  
          </template>
  
        </div><!--Col-->
      </div><!--Row-->
    </div>
  </template>
  <script>  
  export default {
    
    data () {
      return {
        processing: false,
        total: 0,
        queries: {},
        downloads: [],
        records: [],
        fields: [],
        form: {},
        options: {
          portals: []
        }
      }
    },

    mounted() {
      this.refreshRecords();
    },

    created() {
      this.setFields();
      this.resetForm();
      this.resetQueries();
      this.resetRecords();
      this.setURLParams();
      this.getRecords();
      this.getPortals();
    },
  
    methods: {


      setFields () {
          this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/app_fields.json')
          .then(response => {
              if(response && response.data) {
                  this.fields = response.data[this.$route.meta.base_url];
              }
          })
        },
  
      resetQueries() {
        this.queries = {
          limit: 10,
          sort: 'created_at',
          sortDirection: 'desc'
        }
      },
  
      resetForm() {
        this.form = {
          name: '',
          redirect_url: '',
        }
      },
  
      resetRecords() {
        this.records = [];
        this.total = 0;
        this.queries.skip = 0;
      },
  
      updateSortDirection() {
  
        if(this.processing) {
          return;
        }
  
        if(this.queries.sortDirection == 'asc') {
          this.queries.sortDirection = 'desc';
        } else {
          this.queries.sortDirection = 'asc';
        }
  
        this.resetRecords();
  
        this.getRecords();
      },
  
      sortColumn(column) {
  
        if(this.processing) {
          return;
        }
  
        this.queries.sort = column.key;
  
        this.updateSortDirection();
      },

      searchRecords() {
        this.resetRecords();
        this.getRecords();
      },
  
      getRecords() {
  
        this.processing = true;
  
        this.updateURL();
        this.getTotal();
  
        this.$http.get(this.buildQueries(this.$route.meta.base_url, this.queries))
        .then(response => {
          if(response && response.data) {
              
              if(response.data.length) {
  
                  var self = this;
  
                  response.data.forEach(function(data) {
                      self.records.push(data);
                  });
              }
              
              this.queries.skip = this.records.length;
  
              this.processing = false;
          }
        })
      },
  
      getTotal() {
        this.$http.get(this.buildQueries(this.$route.meta.base_url+'/total', this.queries))
        .then(response => {
          if(response && response.status === 200) {
            this.total = response.data;
            this.setTitle();
          }
        })
      },
  
      updateURL() {
        history.pushState({}, null, this.buildQueries(this.$route.meta.base_url, this.queries));
      },
  
      setTitle() {
        document.title = this.total + ' ' + this.$route.meta.title + 's';
      },
  
      postForm() {
        this.processing = true;

        if(!this.form.name) {
            this.form.name = this.form.redirect_url;
        }

        this.$http.post(this.$route.meta.base_url, this.form)
        .then(response => {
          if(response && response.data) {
  
            this.processing = false;
  
            if(response.status === 201) {
              this.resetForm();
              this.total++;
              this.records.unshift(response.data);
            }
  
          }
        })
      },
  
      setPortalName(record) {
  
        if(!record.portal) {
          return;
        }
  
        var portal = _.find(this.options['portals'], {'uuid': record.portal});
  
        if(!portal || !portal.name) {
          return;
        }
  
        return portal.name;
      },
  
      getPortals () {
        this.$http.get('/portals?limit=1000&sort=default&sortDirection=desc&fields=uuid,name,link')
            .then(response => {
              if (response && response.data) {
                this.options['portals'] = response.data;

                if(!this.form.portal && this.options['portals'] && this.options['portals'].length) {
                  this.form.portal = this.options['portals'][0].uuid;
                }
              }
            })
      },

      
    },
  }
  </script>