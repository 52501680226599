<template>
  <div>

    <tool-bar
      :form="form"
    />

    <div class="row">

        <div class="col-sm-12 col-md-3 d-print-none mb-3">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="postForm()">
                <div class="form-group">
                  <label for="name">{{ $route.meta.title }} Name</label>
                  <input
                    id="name"
                    v-model="form.name"
                    type="text"
                    class="form-control"
                  >
                </div>
                <div class="form-group">
                  <label for="description">{{ $route.meta.title }} Description</label>
                  <textarea
                    id="description"
                    v-model="form.description"
                    class="form-control"
                    rows="3"
                  />
                </div>

                <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
                  :hidden="form.deleted_at || form.archived_at"
                >
                  Save
                </button>
              </form>

            </div><!--Body-->
          </div><!--Card-->

          <audit-logs :form="form" class="mt-3" />

        </div><!--Col-->

      <div class="col-sm-12 col-md-3">


      </div><!--Col-->

    </div><!--Row-->
  </div>
</template>

<script>

export default {

  data() {
        return {
            processing: false,
            searching: false,
            form: {},
            from_date: 'start_month',
            to_date: 'end_month',
            filter_to_date: '',
            custom_from_date: '',
            custom_from_time: '00:00',
            custom_to_date: '',
            custom_to_time: '23:59',
            search_exports: '',
            datasets: [],
            report: [],
            report_exports: [],
            chosenEmails: [],
            date_format: 'MM/DD/YY HH:mm z',
            custom_date_format: 'YYYY-MM-DD HH:mm'
        }
    },

    created () {
        this.getRecord();
    },

    methods: {

        postForm() {
            this.processing = true;

            this.$http.post('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
            .then(response => {
                if (response && response.data) {
                    if(response.data.updated_at) {
                      this.form = {...this.form, ...response.data}
                      this.$eventBus.$emit('alerts', {message: this.$route.meta.title + ' saved', type: 'success'});
                    }

                    this.processing = false;
                }
            })
        },

        setTitle() {
            if(this.form.name) {
                document.title = this.form.name + ' | '+this.$root.title;
            }
        },

        getRecord () {
            this.processing = true;
            this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
            .then(response => {
                if (response && response.data) {
                    
                    this.form = response.data;

                    this.processing = false;
                }
            })
        },

        restoreRecord() {
          this.processing = true;
          this.$http.put('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/restore')
          .then(response => {
            if (response && response.status) {

              if(response.status === 200) {
                this.form.deleted_at = null;
                this.form.archived_at = null;
                this.form.updated_at = new Date().toISOString();
              }

              this.processing = false;
            }
          })
        },

        trashRecord () {
            this.processing = true;
            this.$http.delete('/'+this.$route.meta.base_url+'/'+this.form.uuid)
            .then(response => {
                if(response && response.status) {

                  if(response.status === 204) {
                    this.form.deleted_at = new Date().toISOString();
                  }

                  this.processing = false;

                }
            })
        },


    }
}
</script>
