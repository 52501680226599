<template>
    <div>
    
      <div class="row">
        <div class="col-md-3 col-xs-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <p class="card-title">
                    Edit {{ $route.meta.title }}
                  </p>
                </div><!--Col-->
  
                <div class="col-sm-12 col-md-6 text-end">
                </div><!--Col-->
              </div><!--Row-->
  
              <form @submit.prevent="postForm()">
  
                <div class="form-group">
                  <label for="name">Name</label>
                  <input
                    id="name"
                    v-model="form.name"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="redirect_url">Redirect URL</label>
                  <input
                    id="redirect_url"
                    v-model="form.redirect_url"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                <label for="portal">Portal

                <template v-if="form.portal">
                  <a
                  :href="'/portals/'+form.portal"
                  target="_blank"
                  data-tooltip="Edit Portal">
                  <i class="fa fa-edit"></i> <span class="sr-only">Edit Portal</span>
                  </a>
                </template>
                
                </label>
                <select
                  id="portal"
                  v-model="form.portal"
                  class="form-control"
                  :disabled="processing"
                  required
                >
                  <option value="">
                    Choose Portal
                  </option>
                  <template v-for="portal in portals">
                    <option
                      :key="portal.uuid"
                      :value="portal.uuid"
                    >
                      {{ portal.name }}
                    </option>
                  </template>
                </select>
              </div>

                <div class="form-group">
                  <label for="url">URL</label>
                  <input id="url" class="form-control" v-model="form.url" required>
                </div>

  
                <button type="submit" class="btn btn-success" :disabled="processing">Save</button>
    
              </form>
            </div><!--Body-->
          </div><!--Card-->
  
          <audit-logs
            class="mt-3"
            :form="form"
          />
        </div><!--Col-->
  
        <div class="col-md-6">

        </div><!--Col-->
  
        <div class="col-md-3">
  

  
        </div><!--Col-->
  
      </div><!--Row-->
    </div>
  </template>
  <script>  
  export default {
      
    data () {
      return {
        processing: false,
        total: 0,
        queries: {
          limit: 100,
          fields: 'name,uuid,link,portal_reference,redirect,created_at',
          sort: 'name',
          sortDirection: 'asc'
        },
        records: [],
        form: {},
        qr_code: null,
        portals: [],
        portal: {},
      }
    },

  
    created() {
      this.resetForm();
      this.setURLParams();
      this.getRecord();
      this.getPortals();
    },
  
    methods: {

  
      postForm() {
        this.processing = true;
  
        this.$http.post('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
        .then(response => {
          if(response && response.data) {
  
            this.processing = false;
  
            if(response.data.updated_at) {
              this.form.updated_at = response.data.updated_at;
              this.getPortal();
            }
  
          }
        })
      },
  
      resetForm() {
  
  
          this.form = {
            name: null,
            url: null,
            redirect_url: null,
            portal: null,
          }
      },

     getRecord() {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
      .then(response => {
        if(response && response.data) {
  
          if(response.data.uuid) {
            this.form = response.data;
            this.getPortal();
          }
  
          this.processing = false;
        }
      })
     },
  
      getPortals () {
          this.$http.get('/portals?limit=1000&sort=name&sortDirection=asc&fields=uuid,name,link')
          .then(response => {
              if (response && response.data) {
                  this.portals = response.data;
              }
          })
      },
  
      getLocations () {
        this.$http.get('/locations?limit=1000&sort=name&sortDirection=asc&fields=uuid,name,link')
            .then(response => {
              if (response && response.data) {
                this.locations = response.data;
              }
            })
      },
  
      getPortal() {
  
        if(!this.form.portal) {
          return;
        }
  
        if(this.portal && this.portal.uuid) {
          if(this.form.portal == this.portal.uuid) {
            return;
          }
        }
  
        this.$http.get('/portals/'+this.form.portal+'?fields=domain,link,uuid')
        .then(response => {
          if(response && response.data) {
            this.portal = response.data;
            }
        })
      },
  
  

  
        restoreRecord () {
            this.processing = true;
            this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
            .then(response => {
                if (response && response.data) {
  
                    this.processing = false;
  
                    if(response.status === 200) {
                        this.form.deleted_at = null;
                        this.form.archived_at = null;
                    }
                    
                }
            })
        },
  
        archiveRecord () {
            this.processing = true
            this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
            .then(response => {
                if (response && response.data) {
  
                    this.processing = false;
  
                    if(response.status === 200) {
                        this.form.archived_at = new Date().toISOString();
                    }
  
                }
                
            })
        },
  
        trashRecord () {
            this.processing = true;
            this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
            .then((response) => {
              if(response && response.status) {
                this.processing = false;
  
                if(response.status === 204) {
                  this.form.deleted_at = new Date().toISOString();
                }
              }
            })
        },
  
      copyToClipboard(value) {
          navigator.clipboard.writeText(value);
      },

  
      setPortalLink() {
        if(!this.portal || !this.portal.link) {
          return;
        }
  
        if(!this.form || !this.form.url) {
          return;
        }
  
        return 'https://'+this.portal.link+'.'+this.portal.domain+'/r/'+this.form.url;
      },
  
    },
  }
  </script>