<template>
  <div>
    
    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <form
              role="form"
              @submit.prevent="postForm()"
            >

              <div class="form-group">
                <label for="name">Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="reference">Reference</label>
                <input
                  id="reference"
                  v-model="form.reference"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="description">Description</label>
                <textarea
                  id="description"
                  v-model="form.description"
                  class="form-control"
                  rows="3"
                />
              </div>

                  <div class="form-group">
                    <label for="address">Address</label>
                    <input
                      id="address"
                      v-model="form.address"
                      type="text"
                      class="form-control"
                    >
                  </div>

                  <div class="form-group">
                    <label for="address_2">Address 2</label>
                    <input
                      id="address_2"
                      v-model="form.address_2"
                      type="text"
                      class="form-control"
                    >
                  </div>


                  <div class="form-group">
                    <label for="city">City</label>
                    <input
                      id="city"
                      v-model="form.city"
                      type="text"
                      class="form-control"
                    >
                  </div>


                  <div class="form-group">
                    <label for="county">County</label>
                    <input
                      id="county"
                      v-model="form.county"
                      type="text"
                      class="form-control"
                    >
                  </div>


                  <div class="form-group">
                    <label for="state">State</label>
                    <select
                      id="state"
                      v-model="form.state"
                      class="form-control"
                      :disabled="processing"
                    >
                      <option value="" />
                      <option
                        v-for="state in states"
                        :key="state.key"
                        :value="state.key"
                      >
                        {{ state.label }}
                      </option>
                    </select>
                  </div>
   
                  <div class="form-group">
                    <label for="zip">Zip</label>
                    <input
                      id="zip"
                      v-model="form.zip"
                      type="text"
                      class="form-control"
                    >
                  </div>


                  <div class="form-group custom-control custom-switch">
                    <input
                      id="portal"
                      v-model="form.portal"
                      type="checkbox"
                      class="custom-control-input"
                    >
                    <label
                      class="custom-control-label"
                      for="portal"
                    > Enable on Portals</label>
                  </div>
               
                  <div class="form-group custom-control custom-switch">
                    <input
                      id="default"
                      v-model="form.default"
                      type="checkbox"
                      class="custom-control-input"
                    >
                    <label
                      class="custom-control-label"
                      for="default"
                    > Default Location</label>
                  </div>

                  <div class="form-group custom-control custom-switch">
                    <input
                      id="disable_messages"
                      v-model="form.disable_messages"
                      type="checkbox"
                      class="custom-control-input"
                    >
                    <label
                      class="custom-control-label"
                      for="disable_messages"
                    > Auto-Disable Messages</label>
                  </div>
        
                  <button type="submit" class="btn btn-success" :disabled="processing">Save</button>
              
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="form && form.uuid">
          <audit-logs class="mt-3" :form="form"></audit-logs>
        </template>

      </div><!--Col-->

      <div class="col-sm-12 col-md-9">

        <template v-if="form && form.lat && form.lng">
          <location-maps :record="form" />
        </template>

      </div><!--Col-->

    </div><!--Row-->
  </div>
</template>

<script>
export default {

  data () {
    return {
      processing: false,
      form: {},
      states: [],
      timezones: [],
      portal: {},
    }
  },

  created () {
    this.resetForm();
    this.getRecord();
    this.getStates();
    this.getTimezones();

  },

  methods: {

    resetForm() {
        this.form = {
            name: null,
            address: null,
            address2: null,
            city: null,
            state: null,
            zip: null,
            country: null,
            lat: null,
            lng: null,
            portal: false,
            default: false
        }
    },

    getStates () {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/states.json')
      .then(response => {
        if (response && response.data) {
          this.states = response.data;
        }
      })
    },

   getTimezones() {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/timezones.json')
        .then(response => {
            if(response && response.data) {
                this.timezones = response.data;
            }
        })
    },

    getRecord () {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
      .then((response) => {
        if (response && response.data) {
          this.form = response.data;
          this.processing = false;

          this.getPortal();

          if(this.form.name) {
            this.setTitle();
          }

        }
      })
    },

    getPortal() {
        this.$http.get('/portals?default=1&fields=uuid,link,domain,name&limit=1')
            .then(response => {
              if(response && response.data) {

                if(response.data[0]) {
                  this.portal = response.data[0];
                }
              }
            })
    },
    
    setTitle() {
      document.title = this.form.name + ' | Edit '+ this.$route.meta.title;
    },

    postForm () {
      this.processing = true;
      this.$http.post('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if (response && response.status) {

          this.processing = false;

          if(response.data.updated_at) {
            this.$eventBus.$emit('alerts', {message: this.$route.meta.title + ' saved', type: 'success'});
            this.form = {...this.form, ...response.data};
          }

        }
      })
    },

    trashRecord () {
      this.processing = true;
      this.$http.delete('/'+this.$route.meta.base_url+'/'+this.form.uuid)
      .then(response => {
        if(response && response.status) {

          if(response.status === 204) {
            this.$eventBus.$emit('alerts', {message: this.$route.meta.title + ' trashed', type: 'success'});
            this.form.deleted_at = new Date().toISOString();
          }

          this.processing = false;
        }
      })
    },

    copyToClipboard(value) {
        navigator.clipboard.writeText(value);
    }


  }
}
</script>
