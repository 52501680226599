<template>
  <div>
  <div id="main" class="app">

    <template v-if="$route.meta.requiresAuth">
      <nav-bar />

      <div class="px-3 my-3">

      <div class="row">
        <div class="col-12">

      <template v-if="$root.agency && $root.agency.name">
      <div class="dropdown d-print-none">
        <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" :disabled="processing">
          {{ $root.agency.name }}
          <template v-if="$root.agency.processing_mode">
            <span class="badge badge-pill text-uppercase bg-primary" :class="setModeClass($root.agency.processing_mode)">{{ $root.agency.processing_mode }}</span>
          </template>
        </button>
        <ul class="dropdown-menu">
          <template v-for="agency in agencies">
            <li :hidden="agency.uuid === $root.agency.uuid"><a class="dropdown-item" @click="switchAgency(agency)">{{ agency.name }}</a></li>
          </template>
        </ul>
      </div>
      </template>

        </div><!--Col-->

      </div><!--Row-->
      </div>
    </template>

    <div class="px-3">
      <router-view />

      <template v-if="$route.meta.requiresAuth">
        <app-alerts />
      </template>

      </div>

  </div>
</div>
</template>
<script>
export default {

    data() {
        return {
          processing: false,
          agency: {},
          agencies: []
        }
    },

  created() {

      if(this.$route.meta.requiresAuth) {
        this.getCurrentUser();
        this.getSettings();
        this.getAgencies();
      }

    },

  methods: {

    getSettings() {
      this.$http.get('/settings')
      .then(response => {
        if(response && response.data) {
          this.$root.agency = response.data;
          localStorage.setItem('settings', JSON.stringify(response.data));
        }
      })
    },

    getAgencies() {
      this.$http.get('/agencies?sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.agencies = response.data;
        }
      })
    },

    switchAgency(agency)
    {
      this.processing = true;
      this.$http.get('/settings?agency='+agency.uuid)
      .then(response => {
        if(response && response.data) {
          this.processing = false;
          window.location.reload();
        }
      })
    },

    setModeClass(value) {
      if(!value) {
        return;
      }

      if(value == 'live') {
        return 'bg-success';
      }

      return 'bg-primary';
    }

  }
}
</script>
